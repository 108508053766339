<template lang="html">
  <p>aqui va el perfil del usuario logueado</p>
</template>

<script>
  export default {
      name: 'ProfileUserPage'
  }
</script>

<style lang="css" scoped >

</style>
