<template>
  <div class="container">
    <div class="container__center">   
      <span class="container__center__ileft">
        <icono
          icon="mdiTableChair"
          :size="40"
          class="container__center__ileft__icon"
        ></icono>
      </span>    
      <span class="container__center__iright">
        <icono
          icon="mdiTableChair"
          :size="40"
        ></icono>       
      </span>      
    </div>    
  </div>    
</template>

<script>
  export default {
    name: 'AppLoading'
  }
</script>

<style lang="scss" scoped>

  .container {
    position: fixed;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: rgba(39, 41, 41, 0.404);
    z-index: 1000;
    &__center {
      display: block;
      width: 100px;
      height: 100px;
      position: relative;
      overflow: hidden;
      color: $color-black;
      &__ileft{
        position: absolute;
        width: 40px ;
        height: 40px ;
        top: 30px;
        left: 22px;
        transform: scaleX(-1); 
        &__icon {              
          animation: move-left 2s ease-out 1s infinite;                       
        }
      }
      &__iright {
        width: 40px ;
        height: 40px ;
        position: absolute;
        top: 31px;
        left: 33px;
        animation: move-right 2s ease-out 1s infinite;        
      }
    }
  }

  @keyframes move-left {
    from {
      transform: rotate(0deg);      
    }
    to {      
      transform: rotate(-360deg);
    }
  }
  @keyframes move-right {
    from {
      transform: rotate(360deg);      
    }
    to {
      transform: rotate(0deg);
    }
  }  
 
 
</style>
