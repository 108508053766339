import { mapActions, mapGetters } from 'vuex'

export const getColorsTemplateMixin = {
  computed: {
    ...mapGetters({
      temaTempData: 'temaTempData'
    })
  },
  methods: {
    ...mapActions({
      setTemaTemp: 'setTemaTemp'
    }),
    getColorBackground: function (color) {
      if (this.temaTempData) {
        return this.temaTempData.colorBg
      } else {
        return color
      }
    },
    getColorPrimary: function (color) {
      if (this.temaTempData) {
        return this.temaTempData.colorPrimary
      } else {
        return color
      }
    },
    getColorSecondary: function (color) {
      if (this.temaTempData) {
        return this.temaTempData.colorSecondary
      } else {
        return color
      }
    },
    getColorText: function (color) {
      if (this.temaTempData) {
        return this.temaTempData.colorText
      } else {
        return color
      }
    },
    getColorBorder: function (color) {
      if (this.temaTempData) {
        return this.temaTempData.colorBorder
      } else {
        return color
      }
    },
  }
}