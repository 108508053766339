<template lang="html">
  <div class="">
    <v-base-card-flotaing
      title="Registrar Empresa"
      postAction="Guardar"
      :showClose="false"
      :closeMethod="irPerfil"
      :postMethod="guardarDatos"
    >
      <div class="data-user">
        <div class="input-form">
          <p class="input-form__lbl">Nombre Empresa:</p>
          <input
            type="text"
            class="input-form__in"
            placeholder="Nombre Empresa"
            required
            v-model="empresa">
        </div>
        <div class="input-form">
          <p class="input-form__lbl">Crea tu URL <b> (IMPORTANTE)</b> (solo letras):</p>
          <input
            type="text"
            class="input-form__in"
            placeholder="elbuencafe"
            pattern="[A-Za-z0-9]+" 
            required
            v-model="urlname">
        </div>
        <p class="data-user__alert">¿Por que es importante la URL?</p>
        <p class="data-user__alert" style="color: red;">{{ msjError }}</p>
      </div>
    </v-base-card-flotaing>
  </div>
</template>

<script>
  // api
  import apiUsers from '@/api/users.js'
  // vuex
  import { mapActions } from 'vuex'
  // components
  import VBaseCardFlotaing from '@/components/base/VBaseCardFlotaing.vue'

  export default {
    components: {
      VBaseCardFlotaing
    },
    data () {
      return {
        empresa: '',
        urlname: '',
        msjError: ''
      }
    },
    watch: {
      urlname: function (value) {
        let newValue = value.replace('ñ', 'n')
        this.urlname = (newValue.replace(/ /g,'').trim()).toLowerCase()
        // this.urlname = this.hasLowerCase(value)
      }
    },
    methods: {
      ...mapActions({
        changeGlobalLoading: 'changeGlobalLoading'
      }),
      guardarDatos: function () {
        let data = {
          'urlname': this.urlname,
          'empresa': this.empresa,
          'changeurl': true,
        }
        if (this.validateData()) {
          this.msjError = ''
          this.changeGlobalLoading(true)
          apiUsers.userUpdateData(data).then(
            (response) => {
              this.changeGlobalLoading(false)
              location.reload()
            }
          ).catch (
            (error) => {
              this.changeGlobalLoading(false)
              this.msjError = 'Esta URL ya fue tomada, intenta otra URL'
              console.log(error)
            }
          )
        } else {
          console.log('Error: ingresa todo los datos, URL +5 caracteres, Empresa + 6 Caracteres')
          this.msjError = 'Error: ingresa todo los datos, URL +5 caracteres, Empresa + 6 Caracteres'
        }
      },
      validateData: function () {
        if ((this.empresa.length < 6)||(this.urlname.length < 5)) {
          return false
        } else {
          return true
        }
      },
      irPerfil: function () {
        console.log('ir a perfil de usuario')
      }
    }
  }
</script>

<style lang="scss" scoped >
  .data-user {
    display: block;
    &__alert {
      font-family: $ff-3;
      font-size: 13px;
      color: $color-10;
      &:hover {
        cursor: pointer;
      }
    }
  }
</style>
