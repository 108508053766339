// importamos los tipos de mutacion

import * as types from './mutation-types'

const matutaions = {
  [types.LOGIN] (state) {
    // operacion pendiente de repuesta
    state.pending = true
  },
  [types.LOGIN_SUCCESS] (state) {
    // cambio de estado login corrcto
    state.isLoggedIn = true
    state.pending = false
    state.errorMsj = ''
  },
  [types.LOGOUT] (state) {
    // cambio de estado secion terminada
    state.isLoggedIn = false
    state.pending = false
  },
  [types.ERROR_USER] (state, msj) {
    //
    state.errorMsj = msj
    state.isLoggedIn = false
  },
  [types.SET_USER] (state, data) {
    // seteamos el estado con un obeto user
    state.userData = data
  },
}

export default matutaions
