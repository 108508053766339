<template>
  <div class="container">
    <div class="header">
      <h1 class="header__logo">La escondida</h1>
      <div class="header__down">
        <div class="header__categories">
          <div class="header__categories__category" v-for="category in 4" :key="category" >
            <a href="#" class="header__categories__category__name">cuy</a>          
          </div>
          <icono
            icon="mdiChevronRight"
            :size="30"
            class="header__categories__right"
          ></icono>  
        </div>
      </div>
      
    </div> 
    
    <div class="body">
      <div class="category" v-for="category in 3" :key="category">
        <h3 class="category__title"> para 2</h3> 
        <div class="category__down">
          <div class="category__product" v-for="producto in 7" :key="producto">
            <div class="category__product__up">
              <img src="../../../assets/not-image.png" alt="" class="category__product__up__img">
              <a href="#" class="category__product__up__info">i</a>
            </div>          
            <div class="category__product__down">
              <p class="category__product__down__name">cuy al horno entero cuy al</p>
              <p class="category__product__down__price"> 15000 </p>
            </div>
          </div>
        </div>        
      </div>
    </div>    
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
  .container {
    width: 100%;
    height: 100vh;
    background: $color-white;    
    .header {
      height: 80px;
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      position: fixed;
      z-index: 20;
      background: rgb(255, 255, 255);
      opacity: 0.8;  
      &__logo { 
        width: 100%;
        color: black;  
        background: orange;      
      }
      &__down{
        width: 100%;
        overflow: hidden;
        border-bottom: solid black 1px ;        
        background: rgb(253, 253, 253);
        opacity: 0.6;  
        }
      &__categories {
        display: flex;
        height: 33px;        
        &__category {  
          width: 80px;
          max-height:23px;          
          overflow: hidden;
          text-align: center;    
          padding: 3px;
          margin:2px;
          border-radius: 15px;
          border: solid 1px black;                 
          font-size: 11px;
          &__name {            
            text-decoration: none;
            color: black;
            text-transform: uppercase; 
          }
        }
        &__right {
          position: absolute;
          color: blanchedalmond;
          right: 0%;
          z-index: 10;
          background: #000;
          opacity: 0.6;
        }
      }
    }
    
    .body {
      position: relative;
      margin: 0 2px ;
      top: 80px;
      bottom: 0px;
      .category {
        width: 100%;
        &__title {
          font-family: $ff-4;
          margin: 0 5px;
          padding: 2px 0;
          text-transform: uppercase;          
        }
        &__down {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;   
          margin: 2px 5px;   
        }
        &__product {
          width: 47%;
          box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
          border-radius: 10px;
          margin: 3px 0;
          &__up {
            position: relative;
            text-align: center;          
            &__img {
              border-radius: 10px;
              object-fit: fill;
              width: 100%;
              height: 140px;            
            }
            &__info {
              position: absolute;
              top: 5px;
              right: 5px;
              display: block;
              width: 14px;
              height: 14px;
              text-align: center;
              font-size: 11px;
              text-decoration: none;
              color: white;
              border:solid 1px white;
              border-radius: 50%;
            }
          }
          &__down {
            display: flex;
            justify-content: space-between;
            margin: 0 2px;
            font-family: $ff-7;
            font-weight: bold;
            padding: 3px;
            max-height: 40px;
            width: 100%;
            overflow: hidden;
            &__name {
              width: 62%;              
            }
            &__price {
              width: 34%; 
            }
          }
        }
      }

    }
  }
</style>