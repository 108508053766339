// services for user api
import axios from 'axios'
// import global settings
import baseConf from '@/Base'

var instance = axios.create({
  baseURL: baseConf.urlApi,
  timeout: 3000
})

const apiPlantilla= {
  getPlantillaAdmin: function (idCarta) {
    //
    return instance.get('/api/carta/by-user/?page=' + page, {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    })
  },
  saveTemaTemplateAdmin: function (pk, data) {
    //
    return instance.patch('/api/tema-carta-update/' + pk + '/', data, {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    })
  },
  listPlantillaAdmin: function () {
    //
    return instance.get('/api/plantilla/list/', {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    })
  },
}

export default apiPlantilla
