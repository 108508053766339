<template>
  <div class="edit-theme">
    <div class="edit-theme__left">
      <panel-edit-them-carta></panel-edit-them-carta>
    </div>
    <div class="edit-theme__right">
      <setter-carta-template />
    </div>
  </div>
</template>

<script>

  import PanelEditThemCarta from '@/components/cartaAdmin/PanelEditThemCarta.vue'
  import SetterCartaTemplate from '@/components/cartaAdmin/SetterCartaTemplate.vue'

  export default {
    name: 'EditTemplatePage',
    components: {
      PanelEditThemCarta,
      SetterCartaTemplate
    },
  }
</script>

<style lang="scss" scoped>
  .edit-theme {
    display: block;
    margin-top: 1em;
    @include desde($large) {
      display: flex;
      justify-content: space-between;
    };
    &__left {
      margin-bottom: 1em;
      @include desde($large) {
        width: 35%;
      };
    }
    &__right {
      @include desde($large) {
        width: 60%;
      };
    }
  }
</style>