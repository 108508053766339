<template>
  <div>
    <svg-icon type="mdi" :path="getIcon" :size="size" />
  </div>
</template>

<script>
  import SvgIcon from '@jamescoyle/vue-icon' // install material design icons - npm install @mdi/js @jamescoyle/vue-icon
  //
  import {
    mdiFacebook,
    mdiInstagram,
    mdiGreasePencil,
    mdiYoutube,
    mdiChefHat,
    mdiCashMultiple,
    mdiCash,
    mdiBookEdit,
    mdiPalette,
    mdiBroom,
    mdiTableChair,
    mdiChevronRight,
    mdiWhatsapp,
    mdiWeb
  } from '@mdi/js'

  export default {
    name: 'Icono',
    props: {
      icon: String,
      size: Number,
    },
    components: {
      SvgIcon,
    },
    data() {
      return {
        iconos: [
          {'name': 'mdiFacebook', 'value': mdiFacebook},
          {'name': 'mdiInstagram', 'value': mdiInstagram},
          {'name': 'mdiGreasePencil', 'value': mdiGreasePencil},
          {'name': 'mdiYoutube', 'value': mdiYoutube},
          {'name': 'mdiChefHat', 'value': mdiChefHat},
          {'name': 'mdiCashMultiple', 'value': mdiCashMultiple},
          {'name': 'mdiCash', 'value': mdiCash},
          {'name': 'mdiBookEdit', 'value': mdiBookEdit},
          {'name': 'mdiPalette', 'value': mdiPalette},
          {'name': 'mdiBroom', 'value': mdiBroom},
          {'name': 'mdiTableChair', 'value': mdiTableChair},
          {'name': 'mdiChevronRight', 'value': mdiChevronRight},
          {'name': 'mdiWhatsapp', 'value': mdiWhatsapp},
          {'name': 'mdiWeb', 'value': mdiWeb},
        ]
      }
    },
    computed: {
      getIcon: function () {
        const icono = this.iconos.find(
          element => element.name === this.icon
        )
        return icono.value
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
