import { createStore } from 'vuex'


// import modules
import moduleUser from './user'
import moduleApps from './apps'
import moduleCarta from './carta'

export default createStore({
  modules: {
    moduleUser,
    moduleApps,
    moduleCarta
  }
})
