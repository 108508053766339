<template>
  <div class="panel-theme" v-if="dataCartaTemplate">
    <div class="panel-theme__ctrls">
      <a href="#" @click="openDatos()" class="panel-theme__ctrls__btn" :class="{activate: showDatos}">
        <icono
          icon="mdiBookEdit"
          :size="30"
          class="panel-theme__ctrls__btn__icon"
          ></icono>  
        <span class="panel-theme__ctrls__btn__text">Datos</span>
      </a>
      <a href="#" @click="openTema()" class="panel-theme__ctrls__btn" :class="{activate: showTema}">
        <icono
          icon="mdiBroom"
          :size="30"
          class="panel-theme__ctrls__btn__icon"
          ></icono> 
        <span class="panel-theme__ctrls__btn__text">Temas</span>
      </a>
      <a href="#" @click="openColores()" class="panel-theme__ctrls__btn" :class="{activate: showColores}">
        <icono
          icon="mdiPalette"
          :size="30"
          class="panel-theme__ctrls__btn__icon"
          ></icono> 
        <span class="panel-theme__ctrls__btn__text">Colores</span>
      </a>
    </div>
    <div class="panel-theme__body">
      <base-form-carta-tema 
        v-show="showDatos"
      />
      <list-carta-templates 
        v-show="showTema"
      />
      <base-colores-carta-tema 
        v-show="showColores"
      />
    </div>
    <div class="panel-theme__footer">
      <router-link
        :to="{name: 'build-carta', params: {'idCarta': $route.params.idCarta}}" 
        class="panel-theme__footer__btn">Volver a Agregar o Editar Productos</router-link>
    </div>
  </div>
</template>

<script>
  // api
  import apiPlato from '@/api/Plato.js'
  import apiCarta from '@/api/Carta.js'
  // vuex
  import { mapActions, mapGetters } from 'vuex'
  //
  import BaseFormCartaTema from '@/components/cartaAdmin/BaseFormCartaTema.vue'
  import ListCartaTemplates from '@/components/cartaAdmin/ListCartaTemplates.vue'
  import BaseColoresCartaTema from '@/components/cartaAdmin/BaseColoresCartaTema.vue'

  export default {
    name: 'PanelEditThemCarta',
    components: {
      BaseFormCartaTema,
      ListCartaTemplates,
      BaseColoresCartaTema
    },
    computed: {
      ...mapGetters({
        dataCartaTemplate: 'dataCartaTemplate',
        listPlatos: 'listPlatos',
      }),
    },
    data() {
      return {
        showDatos: true,
        showTema: false,
        showColores: false,
      }
    },
    methods: {
      ...mapActions({
        changeGlobalLoading: 'changeGlobalLoading',
        setCartaTemplate: 'setCartaTemplate',
        setPlatosCarta: 'setPlatosCarta',
        setTemaTemp: 'setTemaTemp',
      }),
      retriveCarta: function () {
        this.changeGlobalLoading(true)
        apiCarta.getCartaUserAdmin(this.$route.params.idCarta).then(
          (response) => {
            this.setCartaTemplate(response.data) // seteamos objeto carta
            this.changeGlobalLoading(false)
          }
        ).catch (
          (error) => {
            this.changeGlobalLoading(false)
            console.log(error)
          }
        )
      },
      toListCategories: function () {
        this.changeGlobalLoading(true)
        apiPlato.categoriasPorCarta(this.$route.params.idCarta).then(
          (response) => {
            this.setPlatosCarta(response.data)
            this.changeGlobalLoading(false)
          }
        ).catch (
          (error) => {
            this.changeGlobalLoading(false)
            console.log(error)
          }
        )
      },
      openDatos: function () {
        this.showTema = false
        this.showDatos = true
        this.showColores = false
        this.setTemaTemp(null)
      },
      openTema: function () {
        this.showTema = true
        this.showDatos = false
        this.showColores = false
        this.setTemaTemp(null)
      },
      openColores: function () {
        this.showTema = false
        this.showDatos = false
        this.showColores = true
        this.setTemaTemp(null)
      },
    },
    beforeMount () {
      this.retriveCarta()
      this.toListCategories()
    },
  }
</script>

<style lang="scss" scoped>
  .panel-theme {
    display: block;
    padding: 5px;
    @include desde($large) {
      border-right: 1px solid $color-12;
    };
    &__ctrls {
      display: flex;
      align-items: center;
      margin-bottom: 1em;
      &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        width: 100px;
        color: $color-12;
        &.activate {
          border-bottom: 1px solid;
        }
        &__text {
          color: $color-1;
          font-family: $ff-4;
          margin-left: 10px;
        }
      }
    }
    &__body {
    }
    &__footer {
      margin-top: 1em;
      margin-bottom: 2em;
      padding: 10px;
      display: flex;
      justify-content: flex-start;
      &__btn {
        display: block;
        width: 300px;
        background: $color-5;
        color: $color-white;
        padding: 10px;
        text-align: center;
        text-decoration: none;
        font-family: $ff-4;
        border-radius: 10px;
        &:hover {
          opacity: 0.6;
          color: $color-11;
        }
      }
    }
  }
</style>