import * as types from './mutation-types'

import router from '@/router'

import apiUsers from '@/api/users'

export const IsLogin = ({ commit }) => {
  commit(types.LOGIN_SUCCESS)
}

export const recuperarUsuario = ({commit}) => {
  apiUsers.userRetrieve().then(
    (response) => {
      commit(types.SET_USER, response.data)
    }
  ).catch(
    () => {
      // fallo la consulta ponemos la lista em vacio
      localStorage.removeItem('token')
      commit(types.LOGOUT)
      router.push({ name: 'login' })
    }
  )
}

export const logout = ({ commit }) => {
  localStorage.removeItem('token')
  commit(types.LOGOUT)
  // 
  router.push({ name: 'login' })
}