<template lang="html">
  aqui va el home page
</template>

<script>
  export default {
    name: 'HomePage'
  }
</script>

<style lang="scss" scoped >

</style>
