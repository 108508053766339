// importamos los tipos de mutacion

import * as types from './mutation-types'

const mutations = {
  //
  [types.SET_LOADING] (state, flat) {
    // seteamos
    state.loading = flat
  },
  [types.SET_SHOWMSJ] (state, objMsj) {
    // seteamos
    state.globalMsj = objMsj.mensaje
    state.showMensaje = objMsj.flat
  },
}

export default mutations
