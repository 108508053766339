<template lang="html">
  <div class="login">
    <img
      src="../../assets/fondo.jpg"
      class="login__img">
    <div class="login__body">
      <div>
        <h1 class="login__body__title">Ingresa a Vemicarta</h1>
        <p class="login__body__subtitle">Accede con tu cuenta de Google</p>
      </div>
      <login-google />
      <div class="login__body__redes">
        <a
          href="https://www.facebook.com/vemicarta" target="_blank" class="login__body__redes__btn">
            <icono
              class="login__body__redes__btn__icon"
              icon="mdiFacebook"
              :size="30"></icono>
        </a>
        <a href="https://www.instagram.com/vemicarta/" target="_blank" class="login__body__redes__btn red">
          <icono
            class="login__body__redes__btn__icon"
            icon="mdiInstagram"
            :size="30"></icono>
        </a>
        <a href="https://www.youtube.com/channel/UCUzmkjNdjcCPF3epysvFCsg" target="_blank" class="login__body__redes__btn youtube">
          <icono
            class="login__body__redes__btn__icon"
            icon="mdiYoutube"
            :size="30"></icono>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import LoginGoogle from '@/components/users/LoginGoogle.vue'

  export default {
    components: {
      LoginGoogle
    }
  }
</script>

<style lang="scss" scoped >
  .login {
    position: relative;
    width: 100%;
    height: 100vh;
    &__img {
      width: 100%;
      height: 100%;
    }
    &__body {
      background-color:rgba(19, 17, 17, 0.4);
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 90vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      &__title {
        font-family: $ff-1;
        font-size: 20px;
        text-transform: uppercase;
        color: $color-white;
        margin-top: 2em;
        margin-bottom: 1em;
      }
      &__subtitle {
        font-size: 14px;
        font-family: $ff-2;
        color: $color-white;
        text-align: center;
      }
      &__redes {
        display: flex;
        margin-bottom: 2em;
        &__btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: $color-10;
          margin: 10px;
          color: $color-white;
          text-decoration: none;
          &.red {
            background: $color-4;
          }
          &.youtube {
            background: $color-6;
          }
          &__icon {
            margin-bottom: -2px;
          }
        }
      }
    }
  }
</style>
