import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
// util components
import Icono from '../src/utils/Icono.vue'
import AppLoading from '../src/utils/AppLoading.vue'

const app = createApp(App)

app.component('Icono', Icono)
app.component('app-loading', AppLoading)
// funciones como propiedades globales
//
app.use(store).use(router).mount('#app')
