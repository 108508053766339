<template lang="html">
  <div class="header">
    <a href="/" class="header__link">
      <h1 class="header__link__text">Vemicarta</h1>
    </a>
    <div class="header__right" v-if="userData">
      <div class="header__right__menu">
        <a 
          v-if="userData"  
          :href="urlBase + '/' + userData.urlname + '/'" 
          class="header__right__menu__link">Ver Mi Carta</a>
      </div>
      <a href="/" class="header__right__home">Inicio</a>
      <a href="#" @click="showMenu=!showMenu" class="header__user">
        <img
          :src="getImage(userData.avatar)"
          class="header__user__img">
        <p class="header__user__email">{{ userData.email }}</p>
        <ul v-if="showMenu" class="menu-user">
          <li class="menu-user__item">
            <a href="#" @click="logout()" class="menu-user__item__link">Salir</a>
          </li>
          <li class="menu-user__item">
            <a href="https://www.facebook.com/vemicarta/" target="_blank" class="menu-user__item__link">Contactar</a>
          </li>
        </ul>
      </a>
    </div>
  </div>
  <div v-if="userData" class="">
    <more-data-users
      v-if="!userData.changeurl"
    />
  </div>
</template>

<script>
  import baseConf from '@/Base'
  import { mapActions, mapGetters } from 'vuex'
  // assets
  import avatarImg from '@/assets/avatar.png'
  // components
  import MoreDataUsers from '@/components/users/MoreDataUsers.vue'

  export default {
    name: 'MainHeaderUser',
    components: {
      MoreDataUsers
    },
    computed: {
      ...mapGetters({
        isLoggedIn: 'isLoggedIn',
        userData: 'userData',
      })
    },
    data() {
      return {
        urlBase: baseConf.baseDomain,
        showMenu: false
      }
    },
    methods: {
      ...mapActions({
        recuperarUsuario: 'recuperarUsuario',
        logout: 'logout'
      }),
      getImage: function (image) {
        if (image) {
          return image
        } else {
          return avatarImg
        }
      }
    },
    created() {
      this.recuperarUsuario()
    },
  }
</script>

<style lang="scss" scoped >
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: $color-3;
    @include desde($large) {
      padding: 10px 1.5em;
    }
    &__link {
      text-decoration: none;
      font-family: $ff-1;
      &__text {
        font-size: 20px;
        font-weight: bold;
        color: $color-1;
        @include desde($large) {
          font-size: 30px;
        }
      }
    }
    &__right {
      display: flex;
      align-items: center;
      &__home {
        text-decoration: underline;
        font-family: $ff-4;
        font-weight: bold;
        color: $color-1;
        margin-right: 1em;
        &:hover {
          color: $color-10;
        }
      }
      &__menu {
        display: none;
        align-items: center;
        @include desde($medium){
          display: flex;
        };
        &__link {
          font-family: $ff-4;
          text-decoration: underline;
          margin: 5px 10px;
          color: $color-11;
        }
      }
    }
    &__user {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: $color-1;
      font-family: $ff-3;
      position: relative;
      &__img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid $color-12;
        @include desde($large) {
          width: 45px;
          height: 45px;
        }
      }
      &__email {
        width: 100px; // puede ser menos o en px
        white-space: nowrap; // indica que que se muestre en una sola fila
        overflow: hidden; // la parte que se desborda no se muestra
        text-overflow: ellipsis;
        display: none;
        @include desde($medium) {
          display: block;
        };
      }
    }
  }
  .menu-user {
    position: absolute;
    list-style: none;
    width: 100px;
    margin: 0px;
    padding: 0px;
    margin-top: 10px;
    top: 30px;
    right: 5px;
    background: $color-3;
    border: 1px solid $color-4;
    &__item {
      &__link {
        display: block;
        text-align: right;
        padding: 5px 10px;
        font-family: $ff-4;
        color: $color-11;
        &:hover {
          background: $color-12;
        }
      }
    }
  }
</style>
