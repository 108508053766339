<template>
  <div class="add-plato__upload">
    <p class="add-plato__title" style="margin-top: 15px;">Imagenes:</p>
    <div class="add-plato__upload__item">
      <div class="add-plato__upload__item__img">
        <p class="add-plato__upload__item__img__lbl">Imagen Principal</p>
        <input type="file" class="add-plato__upload__item__img__in" @change="getImageOne">
        <img 
          v-if="platoObj.image_one"
          :src="platoObj.image_one" 
          class="add-plato__upload__item__img__picture">
      </div>
      <a href="#" @click="uploadImgMain()" class="add-plato__upload__item__btn">Guardar</a>
    </div>
    <div class="add-plato__upload__item">
      <div class="add-plato__upload__item__img">
        <p class="add-plato__upload__item__img__lbl">Segunda Imagen</p>
        <input type="file" class="add-plato__upload__item__img__in" @change="getImageTwo">
        <img 
          v-if="platoObj.image_two"
          :src="platoObj.image_two" 
          class="add-plato__upload__item__img__picture">
      </div>
      <a href="#" @click="uploadImgTwo()" class="add-plato__upload__item__btn">Guardar</a>
    </div>
    <div class="add-plato__upload__item">
      <div class="add-plato__upload__item__img">
        <p class="add-plato__upload__item__img__lbl">Tercera Imagen</p>
        <input type="file" class="add-plato__upload__item__img__in" @change="getImageThree">
        <img 
          v-if="platoObj.image_three"
          :src="platoObj.image_three" 
          class="add-plato__upload__item__img__picture">
      </div>
      <a href="#" @click="uploadImgThree()" class="add-plato__upload__item__btn">Guardar</a>
    </div>
  </div>
</template>

<script>
  import apiPlato from '@/api/Plato.js'
  // vuex
  import { mapActions } from 'vuex'
  // mixin
  import { resizeImageMixin } from '@/mixins/resizeImageMixin.js'

  export default {
    name: 'UploadImagesPlato',
    mixins: [resizeImageMixin],
    props: {
      platoObj: {
        type: Object,
        default: null
      },
    },
    data() {
      return {
        imageOne: null,
        imageTwo: null,
        imageThree: null,
      }
    },
    methods: {
      ...mapActions({
        changeGlobalLoading: 'changeGlobalLoading'
      }),
      getImageOne (event) {
        this.changeGlobalLoading(true)
        this.optimizePhoto(event.target.files[0]).then(
          (response) => {
            this.changeGlobalLoading(false)
            this.imageOne = response
          }
        )
      },
      getImageTwo (event) {
        this.changeGlobalLoading(true)
        this.optimizePhoto(event.target.files[0]).then(
          (response) => {
            this.changeGlobalLoading(false)
            this.imageTwo = response
          }
        )
      },
      getImageThree (event) {
        this.changeGlobalLoading(true)
        this.optimizePhoto(event.target.files[0]).then(
          (response) => {
            this.changeGlobalLoading(false)
            this.imageThree = response
          }
        )
      },
      uploadImagen: function (data, pk) {
        apiPlato.cambiarImagenPlato(data, pk).then(
          (response) => {
            // location.reload()
            this.platoObj.image_one = response.data.image_one
            this.platoObj.image_two = response.data.image_two
            this.platoObj.image_three = response.data.image_three
            this.changeGlobalLoading(false)
          }
        ).catch(
          (error) => {
            // error al cargar
            console.log(error)
            this.changeGlobalLoading(false)
          }
        )
      },
      uploadImgMain: function () {
        let data = new FormData()
        if (this.imageOne !== null) {
          this.changeGlobalLoading(true)
          //enviamos la imagen
          data.append('pk', this.platoObj.id)
          data.append('tipo', '1')
          data.append('image', this.imageOne)
          this.uploadImagen(data, this.platoObj.id)
        }
      },
      uploadImgTwo: function () {
        let data = new FormData()
        if (this.imageTwo !== null) {
          this.changeGlobalLoading(true)
          //enviamos la imagen
          data.append('pk', this.platoObj.id)
          data.append('tipo', '2')
          data.append('image', this.imageTwo)
          this.uploadImagen(data, this.platoObj.id)
        }
      },
      uploadImgThree: function () {
        let data = new FormData()
        if (this.imageThree !== null) {
          this.changeGlobalLoading(true)
          //enviamos la imagen
          data.append('pk', this.platoObj.id)
          data.append('tipo', '3')
          data.append('image', this.imageThree)
          this.uploadImagen(data, this.platoObj.id)
        }
      }
    },
  }
</script>

<style lang="scss" scoped src="./addPlato.scss">

</style>