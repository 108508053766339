<template lang="html">
  <div class="page">
    <div class="page__content">
      <carta-user-name />
    </div>
  </div>
</template>

<script>
  import CartaUserName from '@/components/carta/CartaUserName.vue'

  export default {
    name: 'CartaPage',
    components: {
      CartaUserName
    }
  }
</script>

<style lang="scss" scoped >
  .page {
    display: flex;
    align-items: center;
    justify-content: center;
    &__content {
      width: 100%;
      @include desde($large){
        width: 75%;
      };
    }
  }
</style>
