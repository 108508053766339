<template>
  <div class="set-carta">
    <div class="set-carta__head">
      <p class="set-carta__head__title">Pre visualización:</p>
      <a 
        v-if="userData" 
        :href="urlBase + '/' + userData.urlname + '/'"  
        target="_blank" 
        class="set-carta__head__link">Ver Como cliente</a>
    </div>
    <CartaView></CartaView>
    <!-- <base-simple-template /> -->
    <!-- <base-estandar-template /> -->
  </div>
</template>

<script>
  import baseConf from '@/Base'
  // vuex
  import { mapGetters } from 'vuex'
  //
  import CartaView from '@/components/carta/CartaView.vue'


  export default {
    name: 'SetterCartaTemplate',
    components: {
      CartaView,
    },
    computed: {
      ...mapGetters({
        userData: 'userData',
      })
    },
    data() {
      return {
        urlBase: baseConf.baseDomain,
      }
    },
  }
</script>

<style lang="scss" scoped>
  .set-carta {
    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1em;
      &__title {
        font-family: $ff-4;
        text-transform: capitalize;
        font-weight: bold;
        color: $color-10;
      }
      &__link {
        font-family: $ff-3;
        font-size: 13px;
        text-decoration: none;
        border: 1px solid;
        padding: 5px;
        border-radius: 5px;
        background: $color-8;
        color: $color-3;
        text-transform: capitalize;
        @include  desde($large) {
          font-size: 16px;
          padding: 5px 1em;
        };
      }
    }
  }
</style>