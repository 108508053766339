<template>
  <div class="simple-category" :style="{background: getColorBackground(temaObj.color_bg)}">
    <div class="simple-category__head">
      <img 
        v-if="dataCartaTemplate"
        :src="getImageCarta(dataCartaTemplate)"
        @error="setAltImg"
        class="simple-category__head__logo">
      <h1 class="simple-category__head__title" :style="{color: getColorPrimary(temaObj.color_primary)}">{{ temaObj.title }}</h1>
      <p class="simple-category__head__subtitle" :style="{color: getColorSecondary(temaObj.color_secondary)}">{{ temaObj.subtitle }}</p>
    </div>
    <div class="simple-category__body">
      <div 
        class="simple-category__body__item"
        v-for="categoria in listPlatos" 
        :key="categoria.id">
        <img 
          :src="getImageCategory(categoria)"
          @error="setAltImg" 
          class="simple-category__body__item__img">
        <fieldset class="simple-item__content" :style="{borderColor: getColorBorder(temaObj.color_border)}">
          <legend class="simple-item__content__title" :style="{color: getColorPrimary(temaObj.color_primary)}">{{ categoria.name }}</legend>
          <a
            href="#" 
            @click="openDetail(producto)"
            class="simple-item__content__body"
            v-for="producto in categoria.platos"
            :key="producto.id">
              <p class="simple-item__content__body__name" :style="{color: getColorText(temaObj.color_text)}">{{ producto.name }}</p>
              <p class="simple-item__content__body__price" :style="{color: getColorText(temaObj.color_text)}">{{ getEtiquetaPrecioMain(producto.precios).value }}</p>
          </a>
        </fieldset>
      </div>
    </div>
    <div class="simple-category__footer">
      <p class="simple-category__footer__title" :style="{color: getColorText(temaObj.color_text)}">{{ temaObj.title_pie }}</p>
      <a :href="dataCartaTemplate.fanpage" target="_blank" class="simple-category__footer__subtitle" :style="{color: getColorText(temaObj.color_text)}">{{ temaObj.text_link }}</a>
      <p class="simple-category__footer__text" :style="{color: getColorText(temaObj.color_text), borderColor: getColorPrimary(temaObj.color_primary)}">{{ temaObj.text_pie }}</p>
    </div>
  </div>
  <base-detail-template 
      v-if="showDetail"
      :productoObj="productoSelected"
      :closeFunction="closeDetail"
    >
  </base-detail-template>
</template>

<script>
  // vuex
  import { mapGetters } from 'vuex'
  // mixins
  import { getColorsTemplateMixin } from '@/mixins/getColorsTemplateMixin.js'
  // mixins
  import { notImageMixin } from '@/mixins/notImageMixin.js'
  import { cartaMixin } from '@/mixins/cartaMixin.js'
  
  import BaseDetailTemplate from './BaseDetailTemplate.vue'
  export default {
    name: 'BaseSimpleCategoryImageTemplate',
    mixins: [cartaMixin, getColorsTemplateMixin, notImageMixin,],
    props: {
      temaObj: {
        type: Object,
        default: null 
      },
    },
    components: {
      BaseDetailTemplate,
    },
    computed: {
      ...mapGetters({
        listPlatos: 'listPlatos',
        dataCartaTemplate: 'dataCartaTemplate',
      }),
    },
    data() {
      return {
        productoSelected: null,
        showDetail: false
      }
    },
    methods: {
    },
  }
</script>

<style lang="scss" scoped>
  .simple-category {
    padding: 2em 10px;
    background: #3d3d3d;
    overflow: hidden;
    &__head {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 2em;
      &__logo {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-bottom: 1em;
        @include desde ($medium) {
          width: 70px;
          height: 70px;
        }
        &:hover {
          cursor: pointer;
        }
      }
      &__title {
        font-size: 28px;
        color: #ffaf40;
        font-family: $ff-4;
        @include desde ($medium) {
          font-size: 35px;
        }
      }
      &__subtitle {
        font-size: 40px;
        color: $color-white;
        font-family: $ff-2;
      }
    }
    &__body {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 5px;
      :nth-child(odd) {
        flex-direction: row-reverse;
      }
      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        padding: 5px;
        margin-bottom: 2em;
        :nth-child(odd) {
          flex-direction: row;
        }
        &__img {
          display: block;
          width: 85px;
          height: 85px;
          border-radius: 50%;
          margin-right: 10px;
          margin-left: 10px;
          @include desde ($medium) {
            width: 110px;
            height: 110px;
            margin-right: 15px;
            margin-left: 15px;
          }
          @include desde ($large) {
            width: 140px;
            height: 140px;
          }
        }
      }
    }
  }
  .simple-item {
    &__content {
      display: block;
      border-radius: 1px;
      width: 100%;
      &__title {
        font-size: 12px;
        font-family: $ff-4;
        text-transform: capitalize;
        color: #ffaf40;
        @include desde ($medium) {
          font-size: 15px;
        }
        @include desde ($large) {
          font-size: 18px;
        }
      }
      &__body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        margin-bottom: 10px;
        min-width: 200px;
        &:hover {
          cursor: pointer;
        }
        @include desde ($medium) {
            min-width: 300px;
        }
        &__name {
          font-family: $ff-3;
          font-size: 14px;
          max-width: 180px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $color-white;
          text-transform: uppercase;
          @include desde ($medium) {
            max-width: 400px;
            margin-right: 10px;
            font-size: 15px;
          }
          @include desde ($large) {
            max-width: 100%;
            margin-right: 1em;
          }
        }
        &__price {
          font-size: 16px;
          font-family: $ff-11;
          color: $color-white;
          @include desde ($medium) {
            font-size: 18px;
          }
        }
      }
    }
  }
  .simple-category__footer {
    padding: 1em;
    text-align: center;
    color: white;
    font-family: $ff-1;
    font-size: 12px;
    text-transform: uppercase;
    @include desde ($medium) {
      font-size: 13px;
    }
    &__title {
      margin-bottom: 5px;
    }
    &__subtitle {
      margin-bottom: 10px;
      display: block;
      text-decoration: none;
    }
    &__text {
      font-size: 15px;
      font-family: $ff-4;
      padding: 10px;
      border-top: 1px solid #ffaf40;
    }
  }
</style>