import { createRouter, createWebHistory } from 'vue-router'

import homeRutes from './home.js'
import userRutes from './user.js'
import cartaRutes from './carta.js'

// const routes = homeRutes.concat(userRutes, torneoRutes, torneoAdminRutes, homeRutes)
const routes = cartaRutes.concat(userRutes, homeRutes)


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
