<template>
    <div class="container" :style="{background: getColorBackground(temaObj.color_bg)}">
      <div class="header">
        <h1 
          class="header__title"
          :style="{color: getColorPrimary(temaObj.color_primary)}"> {{ temaObj.title }} </h1>
        <h4 class="header__subtitle" :style="{color: getColorText(temaObj.color_text)}">{{ temaObj.subtitle }}</h4>
      </div>

      <!-- categoria ¨Principal -->
      <div class="category-main" v-if="temaObj.show_prod">
        <h2 
          class="category-main__name" 
          :style="{
            background: getColorPrimary(temaObj.color_primary), 
            color: getColorBackground(temaObj.color_bg)}"> 
              {{ temaObj.prod_name }} 
        </h2>
        <p 
          class="category-main__description"
          :style="{color: getColorText(temaObj.color_text)}">{{ temaObj.prod_description }}</p>
        <div class="category-main__prices" >
          <div 
            class="category-main__prices__name" 
            v-for="(tag, index) in temaObj.prod_tags" 
            :key="index">
              <h3 
                class="category-main__prices__name__size"
                v-if="temaObj.prod_tag_shows[index]"
                :style="{color: getColorText(temaObj.color_text)}">{{ tag }} </h3>
              <h2 
                class="category-main__prices__name__price"
                v-if="temaObj.prod_tag_shows[index]"
                :style="{
                  background: getColorPrimary(temaObj.color_primary), 
                  color: getColorBackground(temaObj.color_bg)}"> {{ temaObj.prod_values[index] }}</h2>
          </div>
        </div>
      </div>
      <!-- categoria 1 --> 

      <div class="category" v-for="categoria in listPlatos" :key="categoria.id">
        <h3 
          class="category__name"
          :style="{
            background: getColorPrimary(temaObj.color_primary), 
            color: getColorBackground(temaObj.color_bg)}"> {{ categoria.name }} </h3>

        <!-- producto 1 -->
        <a href="#" @click="openDetail(producto)" class="category__platos" v-for="producto in categoria.platos" :key="producto.id">
          <div class="category__platos__left">
            <img 
              v-if="producto.image_one"
              :src="producto.image_one"
              @error="setAltImg" 
              class="category__platos__left__img">
            <img 
              v-else
              src="../../../assets/plato.png" 
              class="category__platos__left__img">
            <div class="category__platos__left__plato">
              <h3 
                class="category__platos__left__plato__name"
                :style="{color: getColorPrimary(temaObj.color_primary)}"> {{ producto.name }} </h3>
              <p 
                class="category__platos__left__plato__description"
                :style="{color: getColorText(temaObj.color_text)}">
                {{ producto.description }}  </p>
              
            </div>
          </div>
          <div class="category__platos__right">
            <p class="category__platos__right__price">
              <span 
                class="category__platos__right__price__tag"
                :style="{color: getColorText(temaObj.color_text)}">{{ getEtiquetaPrecioMain(producto.precios).tag }}</span>
              <span 
                class="category__platos__right__price__value"
                :style="{color: getColorPrimary(temaObj.color_primary)}">{{ getEtiquetaPrecioMain(producto.precios).value }}</span>
            </p>
            <a 
              href="#"
              v-if="producto.precios.length > 1"
              class="category__platos__right__more"
              :style="{
                color: getColorText(temaObj.color_text), 
                borderColor: getColorPrimary(temaObj.color_primary)}">Ver precios</a>
          </div>
          <div class="category__platos__table">
            <table class="category__platos__table__prices">
              <thead>
                <th v-for="precio in producto.precios" :key="precio.id" > 
                  <p 
                    class="category__platos__table__prices__size"
                    :style="{color: getColorText(temaObj.color_text)}">{{ precio.tag }} </p> 
                </th>
              </thead>
              <tbody>
                <tr> 
                  <td v-for="precio in producto.precios" :key="precio.id" >
                    <p 
                      class="category__platos__table__prices__price"
                      :style="{color: getColorPrimary(temaObj.color_primary)}"> {{ precio.value }} </p> 
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </a>
      </div>

      <!-- footer  --> 
      <div class="footer">
        <h2 class="footer__logo" :style="{color: getColorText(temaObj.color_text)}"> {{ temaObj.title_pie }} </h2>
        <a 
          :href="dataCartaTemplate.fanpage" 
          class="footer__link"
          target="_blank"
          :style="{color: getColorPrimary(temaObj.color_primary)}">{{ temaObj.text_link }}</a>
        <a href="#" class="footer__phone" :style="{color: getColorPrimary(temaObj.color_primary)}">{{ temaObj.text_pie }}</a>
      </div>
    </div>
    <base-detail-template  
      v-if="showDetail"
      :productoObj="productoSelected"
      :closeFunction="closeDetail"
    >
    </base-detail-template>
</template>

<script>
  // vuex
  import { mapGetters } from 'vuex'
  // mixins
  import { getColorsTemplateMixin } from '@/mixins/getColorsTemplateMixin.js'
  // mixins
  import { notImageMixin } from '@/mixins/notImageMixin.js'
  import { cartaMixin } from '@/mixins/cartaMixin.js'
  
  import BaseDetailTemplate from './BaseDetailTemplate.vue'

  export default {
    mixins: [cartaMixin, getColorsTemplateMixin, notImageMixin,],
    props: {
      temaObj: {
        type: Object,
        default: null 
      },
    },
    components: {
      BaseDetailTemplate,
    },
    computed: {
      ...mapGetters({
        listPlatos: 'listPlatos',
        dataCartaTemplate: 'dataCartaTemplate',
      }),
    },
    data() {
      return {
        productoSelected: null,
        showDetail: false
      }
    },
    methods: {
      
    },
  }
</script>

<style lang="scss" scoped>
  .container {
    background: $color-black;
    min-height: 100vh;  
    font-family: $ff-7;
    .header {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding-top: 1em;
      margin-bottom: 2em;      
      &__title {
        color: $color-2;
        font-family: $ff-6;
        font-size: 40px;
        text-align: center;
        line-height: 50px;
      }
      &__subtitle {
        color: $color-white;
        text-align: center;
      }
    }

    .category-main {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
      &__name {
        background: $color-2;
        color: $color-black;        
        padding: 0 0.5em;
        clip-path: polygon(100% 0%, 98% 48%, 100% 100%, 0 100%, 2% 50%, 0 0);
        font-family: $ff-3;
      }
      &__description {
        color: $color-white;
        font-size: 14px;
        margin-top: 0.2em;
        min-height: 30px;
      }
      &__prices {
        display: flex;
        justify-content: space-between;
        margin-top: 0.5em;  
        &__name {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: $color-white;          
          margin:  0.5em;
          text-transform: capitalize;
          clip-path: ellipse(50% 50% at 50% 50%);                   
          &__size {            
            padding: 5px 15px;
            font-size: 15px;
          }
          &__price{
            width: 100%;
            background: $color-2;
            color: $color-1;
            font-size: 20px;
            font-family: $ff-4;
          }
        }
      }
    }

    .category {
      padding:  1em;
      display: flex;
      flex-direction: column;
      //align-items: start;      
      &__name {
        background: $color-2;
        color: $color-black;
        margin-bottom: 1em;
        padding: 0.5em 1em 0.5em 0.5em;
        clip-path: polygon(100% 0%, 95% 50%, 100% 100%, 0 100%, 0 0);
        font-family: $ff-3;
        align-items: start;
        text-transform: capitalize;
        @include desde ($large) {
          width: 50%;
          clip-path: polygon(100% 0%, 98% 50%, 100% 100%, 0 100%, 0 0);
        }
      }
      &__platos {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 1em;
        text-decoration: none;
        &:hover {
          cursor: pointer;
          opacity: 0.5;
        }        
        &__left {
          display: flex;
          align-items: center;
          width: 82%;
          @include desde ($large) {
            width: 60%;
          } 
          &__img {
            display: block;
            width: 40px;
            height: 40px;
          }
          &__plato {
            margin-left: 0.5em;
            
            &__name {
              color: $color-2;
              text-transform: capitalize;
            }
            &__description {
              color: $color-white;
              max-height: 35px;
              font-size: 14px;
              overflow: hidden;
            }
            
          }
        }
        &__right { 
          width: 18%;
          text-align: start;
          justify-content: start;
          @include desde ($large) {
            display: none;
          }   
          &__price {
            color: $color-2;
            font-size: 18px;
            text-align: center;
            font-family: $ff-4; 
            &__tag {
              display: block;
              color: $color-3;
              font-family: $ff-7;
              font-size: 12px;
            }           
          }
          &__more {
            display:block;
            text-align: center;
            text-decoration: none;              
            color: $color-white;
            font-family: $ff-4;
            padding: 2px 5px;
            border-radius: 3px;
            font-size: 11px;
            border: 1px solid $color-2;
          }
        } 
        &__table {
          width: 40%;
          display: none;
          overflow-x: auto;
          &__prices {
            &__size {
              color: $color-3;
              font-family: $ff-7;
              font-size: 12px;
              display: block;
              text-decoration: underline;
              width: 100px;
              white-space: nowrap; 
              overflow: hidden; 
              text-overflow: ellipsis; 
              text-transform: capitalize;
            }
            &__price {
              color: $color-2;
              font-size: 18px;
              text-align: center;
              font-family: $ff-4;
              padding: 5px; 
            }
          }
          @include desde ($large) {
            display: flex;
            justify-content: flex-start;
            flex-direction: row-reverse;
          }   
        }      
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $color-white;
      align-items: center;
      padding: 10px;
      &__logo {
        font-family: $ff-3;
      }
      &__link {
        color: $color-2;
        text-decoration: none;
        &:hover {
          opacity: 0.7;
        }
      }
      &__phone {
        color: $color-2;
        text-decoration: none;
        font-family: $ff-5;
        &:hover {
          color: $color-white;
        }
      }
    }
  }
</style>