// funciones reutilizables para converitr html a pdf o image
//
import html2canvas from 'html2canvas'
import { mapActions } from 'vuex'

export const canvasMixins = {
  methods: {
    ...mapActions({
      changeGlobalLoading: 'changeGlobalLoading'
    }),
    dataURItoBlob: function(dataURI) {
      // convierte los datos de una URL a Blob
      var binary = atob(dataURI.split(',')[1])
      var array = []
      for(var i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i))
      }
      return new Blob([new Uint8Array(array)], {type: 'image/png'})
    },
    downloadURI: function (uri, name) {
      // Descarga un URI BLOB
      var link = document.createElement("a")
      link.download = name
      link.href = URL.createObjectURL(this.dataURItoBlob(uri))
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    downloadDivHtlml (srcEl) {
      // simulamos tiempo de espera
      this.changeGlobalLoading(true)
      window.setTimeout(() => {
        this.changeGlobalLoading(false)
        console.log("generando...")
      }, 3500)
      //
      window.scrollTo(0,0);
      // Convierte un DIV HTML en una canvas procesable this.$refs.capture
       // Save original size of element
      var originalWidth = srcEl.offsetWidth;
      var originalHeight = srcEl.offsetHeight;
      // Force px size (no %, EMs, etc)
      srcEl.style.width = originalWidth + "px";
      srcEl.style.height = originalHeight + "px";
      // Create scaled canvas
      var scaledCanvas = document.createElement("canvas");
      scaledCanvas.width = originalWidth * 2;
      scaledCanvas.height = originalHeight * 2;
      scaledCanvas.style.width = originalWidth + "px";
      scaledCanvas.style.height = originalHeight + "px";
      var scaledContext = scaledCanvas.getContext("2d");
      scaledContext.scale(2, 2);
      html2canvas(
        srcEl,
        { useCORS: true, canvas: scaledCanvas }
      ).then(canvas => {
          //document.body.appendChild(canvas)
          var myImage = canvas.toDataURL("image/png")
          this.downloadURI("data:" + myImage, "DS.png")
      }).catch((error) => {
        console.log("Erorr descargando reporte visual", error)
      })
    }
  }
}
