<template>
  <div>
    <v-base-card-flotaing
      :title="'Agregar Plato a Categoria: ' + categoryObject.name"
      closAction="Cerrar"
      postAction="Guardar"
      :isPost="false"
      :isButton="true"
      :ancho="60"
      :closeMethod="closeAddPlatoCategory"
      :postMethod="guardarDatos"
    >
      <div class="add-plato">
        <p class="add-plato__title">Datos Principales:</p>
        <div class="add-plato__form">
          <div class="input-form">
            <p class="input-form__lbl">Nombre Producto:</p>
            <input
              type="text"
              class="input-form__in"
              placeholder="Pollo al horno con papas..."
              ref="name"
              v-model="platoName">
          </div>
          <div class="input-form">
            <p class="input-form__lbl">Descripción Corta:</p>
            <input
              type="text"
              class="input-form__in"
              placeholder="Pollo + papas + ensalda + gaseosa .."
              v-model="platoDescription">
          </div>
          <div class="input-form">
            <p class="input-form__lbl">Orden en que Aparece:</p>
            <input
              type="number"
              class="input-form__in"
              v-model="platoOrden">
          </div>
        </div>
        <p class="add-plato__title">Agregar Precios:</p>
        <div class="add-plato__prices">
          <div class="add-plato__prices__form">
            <div class="input-form" style="margin-bottom: 0px;">
              <p class="input-form__lbl">Etiqueta (Porcion):</p>
              <input
                type="text"
                class="input-form__in"
                placeholder="Pequeño"
                v-model="precioTag"
                @keyup.enter="addPrecio()">
            </div>
            <div class="input-form" style="margin-bottom: 0px;">
              <p class="input-form__lbl">Valor (Precio):</p>
              <input
                type="number"
                class="input-form__in"
                v-model="precioValue"
                @keyup.enter="addPrecio()">
            </div>
            <div class="check">
              <p class="check__lbl">¿Mostrar como Principal?:</p>
              <input type="checkbox" class="check__in" v-model="precioEstado">
            </div>
            <a href="#" @click="addPrecio()" class="add-plato__prices__form__btn">Agregar</a>
            <p style="color:red;">{{ errorPrecio }}</p>
          </div>
          <div class="add-plato__prices__list">
            <p class="add-plato__prices__list__title">Lista Precios:</p>
            <table class="prices-table" border="1">
              <thead>
                <th>Etiqueta</th>
                <th>Value</th>
                <th>#</th>
              </thead>
              <tbody>
                <tr v-for="(precio, index) in listaPrecios" :key="index">
                  <td class="prices-table__td" :class="{mainClass: precio.main}">{{ precio.tag }}</td>
                  <td class="prices-table__td">{{ precio.value }}</td>
                  <td class="prices-table__td">
                    <div class="prices-table__action">
                      <a href="#" @click="eliminarPrecio(index)" class="prices-table__action__btn">Eliminar</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="add-plato__extra">
          <p class="add-plato__title">Datos Extra</p>
          <div class="input-form">
            <p class="input-form__lbl">Descripción Larga:</p>
            <app-editor-quill 
              :content="platoReceta"
              @change="onEditorChange($event)"
            />
          </div>
        </div>
      </div>
      <p class="alert-text">{{  msjError }}</p>
    </v-base-card-flotaing>
  </div>
</template>

<script>
  import apiPlato from '@/api/Plato.js'
  // utils
  import AppEditorQuill from '@/utils/AppEditorQuill.vue'
  // vuex
  import { mapActions } from 'vuex'
  // components
  import VBaseCardFlotaing from '@/components/base/VBaseCardFlotaing.vue'

  export default {
    name: 'AddPlatoCartaAdmin',
    props: {
      categoryObject: {
        type: Object,
        default: null
      },
      categoryIndex: Number,
      closeAddPlatoCategory: {
        type: Function,
        default: function () {
          console.log('**cerrando...**')
        }
      },
      finishMethodSave: {
        type: Function,
        default: function () {
          console.log('guardado')
        }
      },
    },
    components: {
      VBaseCardFlotaing,
      AppEditorQuill,
    },
    data() {
      return {
        platoName: '',
        platoDescription: '',
        platoOrden: 0,
        precioTag: 'Normal',
        precioValue: 1,
        precioEstado: true,
        platoReceta: '',
        listaPrecios: [],
        msjError: '',
        errorPrecio: ''
      }
    },
    methods: {
      ...mapActions({
        changeGlobalLoading: 'changeGlobalLoading'
      }),
      initData: function () {
        this.platoOrden = this.categoryObject.platos.length + 1
      },
      onEditorChange({ quill, html, text }) {
        this.platoReceta = html
      },
      focusName: function () {
        this.$refs.name.focus()
      },
      addPrecio: function () {
        this.errorPrecio = ''
        if ((this.precioTag.length >2 ) && (parseFloat(this.precioValue) >= 0)) {
          this.listaPrecios.push(
            {
              'tag': this.precioTag,
              'value': this.precioValue,
              'main': this.precioEstado
            }
          )
          this.precioTag = ''
          this.precioValue = 1
          this.precioEstado = false
        } else {
          this.errorPrecio = 'Error: ingrese valor > 0 y etiqueta +2 carcateres'
        }
      },
      eliminarPrecio: function (indice) {
        this.listaPrecios.splice(indice, 1)
      },
      getPrecioMain: function () {
        var precio = 0
        if (this.listaPrecios.length > 0) {
          precio = this.listaPrecios[this.listaPrecios.length - 1]
        }
        for (let i = 0; i < this.listaPrecios.length; i++) {
          const element = this.listaPrecios[i]
          if (element.main == true) {
            precio = element.value
          }
        }
        return precio
      },
      guardarDatos: function () {
        this.msjError = ''
        if (!((this.platoName.length < 4 ) || (this.listaPrecios.length < 1))) {
          this.changeGlobalLoading(true)
          let data = {
            'name': this.platoName,
            'category': this.categoryObject.id,
            'description': this.platoDescription,
            'price': this.getPrecioMain(),
            'receta': this.platoReceta,
            'orden': this.platoOrden,
            'precios': this.listaPrecios,
          }
          apiPlato.createPlato(data).then(
            (response) => {
              this.changeGlobalLoading(false)
              this.finishMethodSave(response.data, this.categoryIndex)
            }
          ).catch(
            (error) => {
              this.changeGlobalLoading(false)
              console.log(error)
            }
          )
        } else {
          this.msjError = 'Ingrese Datos principales y PRECIOS correctamente, nombre +3 caracteres'
        }
      }
    },
    mounted () {
      this.focusName();
      this.initData()
    },
  }
</script>

<style lang="scss" scoped src="./addPlato.scss">
  
</style>