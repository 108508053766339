<template>
  <div>
    <div class="error-404">
      <div class="error-404__content">
        <p class="error-404__content__text">{{ texto }}</p>
        <router-link 
          :to="ruta"
          class="error-404__content__link">{{ action }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'VBaseCardSimple',
    props: {
      texto: String,
      action: String,
      ruta: {
        type: Object,
        default: {
          name: 'login'
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.error-404 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    &__content {
      max-width: 400px;
      padding: 10px;
      background: $color-3;
      &__text {
        color: $color-11;
        font-family: $ff-4;
        text-align: center;
        font-size: 18px;
      }
      &__link {
        display: block;
        text-align: center;
        padding: 10px;
        background: $color-4;
        color: $color-white;
        margin-top: 1em;
        border-radius: 5px;
        text-decoration: none;
        font-family: $ff-3;
        &:hover {
          cursor: pointer;
          opacity: 0.5;
        }
      }
    }
  }
</style>