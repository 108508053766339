<template>
  <!-- ci = circle image -->
  <div class="ci-content" :style="{background: getColorBackground(temaObj.color_bg)}">
    <div class="ci-content__head">
      <p class="ci-content__head__title" :style="{color: getColorText(temaObj.color_text)}">
        <img 
          :src="getImageCarta(dataCartaTemplate)"
          @error="setAltImg"
          class="ci-content__head__title__img">
        <span class="ci-content__head__title__text">{{ temaObj.title }}</span>
      </p>
      <p 
        class="ci-content__head__subtitle"
        :style="{background: getColorBackground(temaObj.color_primary), color: getColorText(temaObj.color_text)}">
        {{ temaObj.subtitle }}
      </p>
    </div>
    <div v-if="temaObj.show_prod" class="ci-content__promo" :style="{borderColor: getColorPrimary(temaObj.color_border)}">
      <!-- productos en promo o items de promo -->
      <h3 
        class="ci-content__promo__title"
        :style="{color: getColorPrimary(temaObj.color_primary)}">
        {{ temaObj.prod_name }}
      </h3>
      <p class="ci-content__promo__subtitle">{{ temaObj.prod_description }}</p>
      <div class="ci-content__promo__body">
        <div 
          class="ci-content__promo__item"
          v-for="(tag, index) in temaObj.prod_tags" 
          :key="index">
          <p 
            class="ci-content__promo__item__tag"
            v-if="temaObj.prod_tag_shows[index]"
            :style="{color: getColorText(temaObj.color_primary)}">{{ tag }}</p>
          <p 
            class="ci-content__promo__item__value"
            v-if="temaObj.prod_tag_shows[index]"
            :style="{color: getColorText(temaObj.color_text), borderColor: getColorPrimary(temaObj.color_border)}">{{ temaObj.prod_values[index] }}</p>
        </div>
      </div>
    </div>
    <div class="ci-content__body">
      <div 
        class="ci-content__body__category" 
        v-for="categoria in listPlatos" 
        :key="categoria.id">
        <a 
          href="#" 
          class="ci-content__body__category__title"
          :style="{color: getColorText(temaObj.color_primary), borderColor: getColorBorder(temaObj.color_border)}">{{ categoria.name }}</a>
        <div class="ci-content__body__category__items">
          <a 
            class="item"
            href="#"
            @click="openDetail(producto)"
            v-for="producto in categoria.platos" 
            :key="producto.id">
            <img
              :src="getImagePlato(producto.image_one)"
              @error="setAltImg"
              class="item__img">
            <div class="item__content">
              <p 
                class="item__content__title"
                :style="{color: getColorText(temaObj.color_primary)}">{{ producto.name }}</p>
              <p 
                class="item__content__text"
                :style="{color: getColorText(temaObj.color_text)}">
                {{ producto.description }}
                </p>
              <p 
                class="item__content__tag"
                :style="{color: getColorText(temaObj.color_primary)}">
                  <span class="item__content__tag__text">Precio:</span> {{ getEtiquetaPrecioMain(producto.precios).value }}
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="ci-content__footer">
      <p 
        class="ci-content__footer__title"
        :style="{color: getColorText(temaObj.color_text)}">{{ temaObj.title_pie }}</p>
      <a 
        class="ci-content__footer__item"
        :style="{color: getColorText(temaObj.color_text)}"
        href="#">
        <icono
          icon="mdiWhatsapp" 
          :size="20"
          class="ci-content__footer__item__icon"></icono>
        <span class="ci-content__footer__item__text">{{ temaObj.text_link }}</span>
      </a>
      <p 
        class="ci-content__footer__item"
        :style="{color: getColorText(temaObj.color_text)}">
        <icono
          icon="mdiWeb" 
          :size="20"
          class="ci-content__footer__item__icon"></icono>
        <span class="ci-content__footer__item__text">{{ temaObj.text_pie }}</span>
      </p>
    </div>
  </div>

  <base-detail-template 
      v-if="showDetail"
      :productoObj="productoSelected"
      :closeFunction="closeDetail"
    >
  </base-detail-template>
</template>

<script>
  // vuex
  import { mapGetters } from 'vuex'
  // mixins
  import { getColorsTemplateMixin } from '@/mixins/getColorsTemplateMixin.js'
  // mixins
  import { notImageMixin } from '@/mixins/notImageMixin.js'
  import { cartaMixin } from '@/mixins/cartaMixin.js'
  import notImg from '@/assets/not-image.png'
  
  import BaseDetailTemplate from './BaseDetailTemplate.vue'

  export default {
    mixins: [cartaMixin, getColorsTemplateMixin, notImageMixin,],
    props: {
      temaObj: {
        type: Object,
        default: null 
      },
    },
    components: {
      BaseDetailTemplate,
    },
    computed: {
      ...mapGetters({
        listPlatos: 'listPlatos',
        dataCartaTemplate: 'dataCartaTemplate',
      }),
    },
    data() {
      return {
        productoSelected: null,
        showDetail: false
      }
    },
    methods: {
      getImagePlato: function (image) {
        if (image) {
          return image
        } else {
          return notImg
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .ci-content {
    padding: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: $color-1;
    &__head {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 10px;
      margin-bottom: 1em;
      @include desde ($large) {
        width: 60%;
      }
      &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        text-align: center;
        color: $color-white;
        &__img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 1px solid;
          padding: 5px;
        }
        &__text {
          margin-left: 10px;
          font-family: $ff-3;
          font-size: 20px;
          font-weight: bold;
          text-align: center;
        }
      }
      &__subtitle {
        background: $color-2;
        padding: 10px 1em;
        color: $color-white;
        font-family: $ff-9;
        text-transform: capitalize;
        text-align: center;
        clip-path: polygon(100% 0%, 98% 48%, 100% 100%, 0 100%, 2% 50%, 0 0);
      }
    }
    &__body {
      @include desde ($large) {
        width: 60%;
      }
      &__category {
        &__title {
          font-size: 18px;
          font-family: $ff-5;
          display: block;
          text-decoration: none;
          margin-bottom: 10px;
          color: $color-white;
          border-bottom: 1px solid $color-4;
          @include desde ($large) {
            font-size: 20px;
            font-weight: bold;
          }
        }
        &__items {
          padding: 10px;
        }
      }
    }
    &__footer {
      color: $color-white;
      &__title {
        font-size: 18px;
        text-align: center;
        text-transform: capitalize;
        font-family: $ff-5;
        margin-bottom: 10px;
      }
      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        display: block;
        &__icon {
          margin-right: 10px;
        }
      }
    }
  }
  .item {
    margin-bottom: 1em;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    text-decoration: none;
    &__img {
      display: block;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-right: 1em;
      @include desde ($medium) {
        width: 100px;
        height: 100px;
      }
      @include desde ($large) {
        width: 120px;
        height: 120px;
      }
    }
    &__content {
      display: block;
      width: 70%;
      @include desde ($large) {
        width: 80%;
      }
      &__title {
        font-size: 15px;
        text-transform: uppercase;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: $ff-3;
        color: $color-2;
        @include desde ($large) {
          font-size: 16px;
        }
      }
      &__text {
        font-size: 14px;
        max-height: 50px;
        overflow: hidden;
        display: block;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        margin-bottom: 7px;
        color: $color-white;
        @include desde ($large) {
          font-size: 16px;
          max-height: 60px;
          margin-bottom: 15px;
        }
      }
      &__tag {
        font-family: $ff-4;
        font-weight: bold;
        color: $color-2;
        &__text {
          margin-right: 10px;
        }
        @include desde ($large) {
          font-size: 18px;
          max-height: 60px;
        }
      }
    }
  }

  .ci-content__promo {
    width: 100%;
    margin-bottom: 1em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid $color-white;
    padding: 5px;
    @include desde ($medium) {
      width: 80%;
    }
    @include desde ($large) {
      width: 70%;
    }
    &__title {
      font-family: $ff-3;
      font-weight: bold;
      color: $color-white;
      font-size: 16px;
      width: 100%; // puede ser menos o en px
      white-space: nowrap; // indica que que se muestre en una sola fila
      overflow: hidden; // la parte que se desborda no se muestra
      text-overflow: ellipsis;
      margin-bottom: 5px;
      @include desde ($medium) {
        font-size: 20px;
      }
    }
    &__subtitle {
      color: $color-white;
      font-size: 14px;
      width: 100%; // puede ser menos o en px
      white-space: nowrap; // indica que que se muestre en una sola fila
      overflow: hidden; // la parte que se desborda no se muestra
      text-overflow: ellipsis;
      @include desde ($medium) {
        font-size: 16px;
      }
    }
    &__body {
      display: flex;
      width: 90%;
      color: $color-white;
      justify-content: center;
      align-items: center;
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 3px;
      margin: 5px;
      &__tag {
        margin-bottom: 5px;
      }
      &__value {
        border: 1px solid $color-white;
        display: block;
        width: 100%;
        padding: 5px 3px;
      }
    }
  }
</style>