<template lang="html">
  <div class="cartas">
    <p class="cartas__title">Mis Cartas creadas:</p>
    <div class="cartas__item" v-for="carta in listaCartas"  :key="carta.id">
      <div class="cartas__item__info">
        <p class="cartas__item__info__name" :title="carta.name">{{ carta.name }}</p>
      </div>
      <div class="cartas__item__ctrls">
        <router-link
          :to="{name: 'build-carta', params: {'idCarta': carta.id}}" 
          class="cartas__item__ctrls__btn">Editar</router-link>
        <a 
          href="#" 
          v-if="carta.activo"
          @click="activarDesactivar(carta)" 
          class="cartas__item__ctrls__btn red">Activo</a>
        <a
          v-else
          href="#"
          @click="activarDesactivar(carta)"
          class="cartas__item__ctrls__btn red"
          :style="{color: '#7f8c8d'}">No Activo</a>
        <a
          href="#"
          @click="openDeleteCarta(carta)"
          class="cartas__item__ctrls__btn delete">Eliminar/Modificar</a>
      </div>
    </div>
    <router-link
      :to="{name: 'add-carta'}"
      class="cartas__main_btn">Agregar Nueva Carta</router-link>

    <edit-form-carta
      v-if="showDeleteCarta"
      :cartaObj="cartaSelected"
      :closeFunction="closeDeleteCarta"
    />

    <!-- codigo qr -->
    <qr-user></qr-user>
  </div>
</template>

<script>
  // vuex
  import { mapActions } from 'vuex'
  // api
  import apiCarta from '@/api/Carta.js'

  import EditFormCarta from '@/components/cartaAdmin/EditFormCarta.vue'
  import QrUser from '@/components/users/QrUser.vue'

  export default {
    name: 'MisCartas',
    components: {
      EditFormCarta,
      QrUser
    },
    data () {
      return {
        listaCartas: [],
        page: 1,
        next: null,
        showDeleteCarta: false,
        cartaSelected: null
      }
    },
    methods: {
      ...mapActions({
        changeGlobalLoading: 'changeGlobalLoading'
      }),
      listarMisCartas: function () {
        this.changeGlobalLoading(true)
        apiCarta.cartasUser(this.page).then(
          (response) => {
            this.listaCartas = this.listaCartas.concat(response.data.results)
            this.changeGlobalLoading(false)
          }
        ).catch (
          (error) => {
            this.changeGlobalLoading(false)
            console.log(error)
          }
        )
      },
      activarDesactivar: function (carta) {
        var data = new FormData()
        data.append('activo', !carta.activo)
        data.append('published', !carta.activo)
        this.changeGlobalLoading(true)
        apiCarta.updateCartaUser(data, carta.id).then(
          (response) => {
            carta.activo = response.data.activo
            //
            this.changeGlobalLoading(false)
          }
        ).catch (
          (error) => {
            console.log(error)
            this.changeGlobalLoading(false)
          }
        )
      },
      closeDeleteCarta: function () {
        this.showDeleteCarta = false
        this.cartaSelected = null
      },
      openDeleteCarta: function (carta) {
        this.cartaSelected = carta
        this.showDeleteCarta = true
      },
    },
    beforeMount: function () {
      this.listarMisCartas()
    }
  }
</script>

<style lang="scss" scoped >
  .cartas {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &__title {
      text-align: left;
      width: 100%;
      font-family: $ff-4;
      margin-bottom: 1em;
      color: $color-1;
      font-size: 15px;
      font-weight: bold;
      text-transform: capitalize;
    }
    &__main_btn {
      display: block;
      border: 1px solid;
      background: $color-8;
      color: $color-white;
      text-align: center;
      padding: 10px;
      border-radius: 10px;
      text-decoration: none;
      font-family: $ff-4;
      margin-top: 1em;
      width: 100%;
      @include desde($medium) {
        width: 50%;
      }
    }
    &__item {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      border: 1px solid $color-5;
      margin-bottom: 10px;
      padding: 10px;
      border-radius: 5px;
      &__info {
        &__name {
          font-family: $ff-4;
          color: $color-5;
          width: 180px; // puede ser menos o en px
          white-space: nowrap; // indica que que se muestre en una sola fila
          overflow: hidden; // la parte que se desborda no se muestra
          text-overflow: ellipsis;
          @include desde($medium) {
            width: 300px;
          }
          @include desde($large) {
            width: 100%;
          }
        }
      }
      &__ctrls {
        display: flex;
        justify-content: center;
        &__btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          margin: 5px;
          text-align: center;
          text-decoration: none;
          border: 1px solid;
          color: $color-8;
          font-family: $ff-3;
          padding: 3px 0px;
          font-size: 14px;
          @include desde($medium) {
            width: 90px;
            font-size: 16px;
          }
          &.red {
            color: #2980b9;
            font-size: 11px;
            white-space: nowrap; // indica que que se muestre en una sola fila
            overflow: hidden; // la parte que se desborda no se muestra
            text-overflow: ellipsis;
            @include desde($medium) {
              width: 90px;
              font-size: 16px;
            }
          }
          &.delete {
            display: none;
            color: $color-6;
            font-size: 14px;
            white-space: nowrap; // indica que que se muestre en una sola fila
            overflow: hidden; // la parte que se desborda no se muestra
            text-overflow: ellipsis;
            width: 150px;
            @include desde($medium){
              display: block;
            };
          }
        }
      }
    }
  }
</style>
