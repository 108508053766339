<template lang="html">
  <div class="">
    <main-header-user />
  </div>
  <div class="content">
    <router-view />
  </div>
</template>

<script>
  import MainHeaderUser from '@/components/includes/MainHeaderUser.vue'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'IndexPage',
    components: {
      MainHeaderUser
    },
    computed: {
      ...mapGetters({
        isLoggedIn: 'isLoggedIn',
      })
    },
    mounted () {
      if (!this.isLoggedIn) {
        this.$router.push({name: 'login'})
      }
    },
  }
</script>

<style lang="scss" scoped >
  .content {
    padding: 5px 1.5em;
    @include desde($large) {
      padding: 10px 6em;
    }
    @include desde($extra-large) {
      padding: 10px 10em;
    }
  }
</style>
