<template lang="html">
  <div class="">
    <carta-view />
  </div>
</template>

<script>
  // api
  import apiPlato from '@/api/Plato.js'
  import apiCarta from '@/api/Carta.js'
  // vuex
  import { mapActions, mapGetters } from 'vuex'
  //
  import CartaView from '@/components/carta/CartaView.vue'


  export default {
    name: 'SetterCartaTemplate',
    components: {
      CartaView
    },
    computed: {
      ...mapGetters({
        dataCartaTemplate: 'dataCartaTemplate',
      }),
    },
    methods: {
      ...mapActions({
        changeGlobalLoading: 'changeGlobalLoading',
        setCartaTemplate: 'setCartaTemplate',
        setPlatosCarta: 'setPlatosCarta',
      }),
      retriveCartaUsername: function () {
        this.changeGlobalLoading(true)
        apiCarta.getCartaUsername(this.$route.params.usernameEmpresa).then(
          (response) => {
            this.changeGlobalLoading(false)
            if (response.data.error) {
              console.log(response.data.error)
            } else {
              this.setCartaTemplate(response.data) // seteamos objeto carta
              this.toListCategories(response.data.id) 
            }
          }
        ).catch (
          (error) => {
            this.changeGlobalLoading(false)
            console.log(error)
          }
        )
      },
      toListCategories: function (id_carta) {
        this.changeGlobalLoading(true)
        apiPlato.categoriasPorCarta(id_carta).then(
          (response) => {
            this.setPlatosCarta(response.data)
            this.changeGlobalLoading(false)
          }
        ).catch (
          (error) => {
            this.changeGlobalLoading(false)
            console.log(error)
          }
        )
      },
    },
    beforeMount () {
      this.retriveCartaUsername()
    },
  }
</script>

<style lang="css" scoped >

</style>
