<template>
  <div>
    <v-base-card-simple 
      texto="No hemos podido encontrar lo que solicitas, intenta navegar desde otras opciones."
      action="Ir a Bingeres"
    />
  </div>
</template>

<script>
  import VBaseCardSimple from '@/components/base/VBaseCardSimple.vue'

  export default {
    name: 'PaginaNoEncontrada',
    components: {
      VBaseCardSimple,
    },
  }
</script>

<style lang="scss" scoped>

</style>