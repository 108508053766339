import HomePage from '@/views/home/HomePage.vue'
import PaginaNoEncontrada from '@/views/home/PaginaNoEncontrada.vue'

const homeRutes = [
  {
    path: '/panel',
    name: 'panel',
    component: HomePage
  },
  {
    path: '/:catchAll(.*)', 
    component: PaginaNoEncontrada,
    name: 'NotFound'
  }
]

export default homeRutes;
