<template lang="html">
  <div class="">
    <add-info-carta />
  </div>
</template>

<script>
  import AddInfoCarta from '@/components/cartaAdmin/AddInfoCarta.vue'

  export default {
    name: 'AddInfoEmpresaCarta',
    components: {
      AddInfoCarta
    }
  }
</script>

<style lang="css" scoped >

</style>
