<template>
  <div class="delete-plato">
    <a @click="showDelete = !showDelete" class="delete-plato__link">Eliminar Plato</a>
    <div class="delete-plato__form" v-if="showDelete">
      <div class="input-form">
        <p class="input-form__lbl">Escribe: ELIMINAR</p>
        <input
          type="text"
          class="input-form__in"
          placeholder="escribe: ELIMINAR"
          v-model="deleteForm">
      </div>
      <a @click="eliminarPlato()" class="delete-plato__form__btn">Eliminar</a>
      <p class="delete-plato__form__alert">{{ msjDelete }}</p>
    </div>
  </div>
</template>

<script>
  import apiPlato from '@/api/Plato.js'
  // utils
  // vuex
  import { mapActions } from 'vuex'

  export default {
    name: 'DeleteFormPlato',
    props: {
      platoObj: {
        type: Object,
        default: null 
      },
    },
    data() {
      return {
        showDelete: false,
        deleteForm: '',
        msjDelete: ''
      }
    },
    methods: {
      ...mapActions({
        changeGlobalLoading: 'changeGlobalLoading'
      }),
      eliminarPlato: function () {
        if (this.deleteForm === 'ELIMINAR') {
          this.changeGlobalLoading(true)
          let data = {
            'anulate': true,
          }
          apiPlato.updatePlato(data, this.platoObj.id).then(
            (response) => {
              this.changeGlobalLoading(false)
              location.reload()
            }
          ).catch(
            (error) => {
              this.changeGlobalLoading(false)
              this.msjDelete = 'No se pudo eliminar'
              console.log(error)
            }
          ) 
        } else {
          this.msjDelete = 'Escriba: ELIMINAR, correctamente'
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.delete-plato {
    margin: 1em 10px;
    &__link {
      font-family: $ff-4;
      font-size: 14px;
      color: $color-6;
      margin-top: 1em;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    &__form {
      &__btn {
        border: 1px solid;
        background: $color-6;
        color: $color-3;
        padding: 5px 1em;
        &:hover {
          cursor: pointer;
          opacity: 0.6;
        }
      }
      &__alert {
        color: $color-6;
        font-family: $ff-4;
        margin-top: 10px;
      }
    }
  }
</style>