<template>
  <div>
    <v-base-card-flotaing
      :title="'Editar Datos De: ' + platoObject.name"
      closAction="Cerrar"
      :isPost="false"
      :isButton="false"
      :ancho="60"
      :closeMethod="closeEditPlato"
    >
      <div class="add-plato">
        <p class="add-plato__title">Datos Principales:</p>
        <div class="add-plato__form">
          <div class="input-form">
            <p class="input-form__lbl">Nombre Plato:</p>
            <input
              type="text"
              class="input-form__in"
              placeholder="Pollo al horno con papas..."
              v-model="platoName">
          </div>
          <div class="input-form">
            <p class="input-form__lbl">Descripción Corta:</p>
            <input
              type="text"
              class="input-form__in"
              placeholder="Pollo + papas + ensalda + gaseosa .."
              v-model="platoDescription">
          </div>
          <div class="input-form">
            <p class="input-form__lbl">Precio Principal (Aparece por defecto):</p>
            <input
              type="number"
              class="input-form__in"
              v-model="platoPrecio">
          </div>
          <div class="input-form">
            <p class="input-form__lbl">Orden en que Aparece:</p>
            <input
              type="number"
              class="input-form__in"
              v-model="platoOrden">
          </div>
          <a @click="guardarDatosPrincipales()" class="add-plato__prices__form__btn">Guardar</a>
        </div>
        <p class="add-plato__title" style="margin-top: 15px;">Agregar Precios:</p>
        <div class="add-plato__prices">
          <div class="add-plato__prices__form">
            <div class="input-form" style="margin-bottom: 0px;">
              <p class="input-form__lbl">Etiqueta (Porcion):</p>
              <input
                type="text"
                class="input-form__in"
                placeholder="Pequeño"
                v-model="precioTag"
                @keyup.enter="addPrecio()">
            </div>
            <div class="input-form" style="margin-bottom: 0px;">
              <p class="input-form__lbl">Valor (Precio):</p>
              <input
                type="number"
                class="input-form__in"
                v-model="precioValue"
                @keyup.enter="addPrecio()">
            </div>
            <div class="check">
              <p class="check__lbl">¿Mostrar como Principal?:</p>
              <input type="checkbox" class="check__in" v-model="precioEstado">
            </div>
            <a @click="addPrecio()" class="add-plato__prices__form__btn">Agregar</a>
          </div>
          <div class="add-plato__prices__list">
            <p class="add-plato__prices__list__title">Lista Precios:</p>
            <table class="prices-table" border="1">
              <thead>
                <th>Etiqueta</th>
                <th>Value</th>
                <th>#</th>
              </thead>
              <tbody>
                <tr v-for="(precio, index) in listaPrecios" :key="index">
                  <td class="prices-table__td" :class="{mainClass: precio.main}">{{ precio.tag }}</td>
                  <td class="prices-table__td">{{ precio.value }}</td>
                  <td class="prices-table__td">
                    <div class="prices-table__action">
                      <a @click="eliminarPrecio(precio, index)" class="prices-table__action__btn">Eliminar</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="add-plato__extra">
          <p class="add-plato__title" style="margin-top: 15px;">Datos Extra</p>
          <div class="input-form">
            <p class="input-form__lbl">Descripción Larga:</p>
            <app-editor-quill 
              :content="platoReceta"
              @change="onEditorChange($event)"
            />
          </div>
          <div class="check" style="margin-bottom: 1em;">
            <p class="check__lbl">¿Mostrar al Publico?:</p>
            <input type="checkbox" class="check__in" v-model="platoPublic">
          </div>
          <a @click="guardarDatosExtra()" class="add-plato__prices__form__btn">Guardar</a>
        </div>
        <upload-images-plato
          :plato-obj="platoObject"
        ></upload-images-plato>
        <delete-form-plato
          :plato-obj="platoObject"
        ></delete-form-plato>
      </div>
      <p class="alert-text">{{ msjErrorMain }}</p>
    </v-base-card-flotaing>
  </div>
</template>

<script>
  import apiPlato from '@/api/Plato.js'
  // utils
  import AppEditorQuill from '@/utils/AppEditorQuill.vue'
  // vuex
  import { mapActions } from 'vuex'
  // components
  import VBaseCardFlotaing from '@/components/base/VBaseCardFlotaing.vue'
  import DeleteFormPlato from './DeleteFormPlato.vue'
  import UploadImagesPlato from './UploadImagesPlato.vue'

  export default {
    name: 'EditPlatoCartaAdmin',
    props: {
      platoObject: {
        type: Object,
        default: null
      },
      categoryIndex: Number,
      platoIndex: Number,
      closeEditPlato: {
        type: Function,
        default: function () {
          console.log('**cerrando...**')
        }
      },
      finishMethodUpdate: {
        type: Function,
        default: function () {
          console.log('guardado')
        }
      },
    },
    components: {
      VBaseCardFlotaing,
      AppEditorQuill,
      UploadImagesPlato,
      DeleteFormPlato
    },
    data() {
      return {
        platoName: '',
        platoDescription: '',
        platoOrden: 0,
        platoPrecio: 0,
        platoPublic: true,
        precioTag: '',
        precioValue: 1,
        precioEstado: true,
        platoReceta: '',
        listaPrecios: [],
        msjErrorMain: '',
      }
    },
    methods: {
      ...mapActions({
        changeGlobalLoading: 'changeGlobalLoading'
      }),
      onEditorChange({ quill, html, text }) {
        this.platoReceta = html
      },
      initDataPlato: function () {
        this.platoName = this.platoObject.name
        this.platoDescription = this.platoObject.description
        this.platoOrden = this.platoObject.orden
        this.platoPrecio = this.platoObject.price
        this.platoPublic = this.platoObject.published
        //
        this.listaPrecios = this.platoObject.precios
        this.platoReceta = this.platoObject.receta
      },
      addPrecio: function () {
        if ((this.precioTag.length >3 ) && (this.precioValue >= 0)) {
          let dataPrecio = {
            'tag': this.precioTag,
            'value': this.precioValue,
            'main': this.precioEstado,
            'plato': this.platoObject.id,
          }
          this.changeGlobalLoading(true)
          apiPlato.createPrecio(dataPrecio).then(
            (response) => {
              if (response.data.main) {
                this.platoObject.price = response.data.value
                this.finishMethodUpdate(this.platoObject, this.categoryIndex, this.platoIndex, false)
              }
              this.listaPrecios.push(response.data)
              this.changeGlobalLoading(false)
            }
          ).catch(
            (error) => {
              this.changeGlobalLoading(false)
              console.log(error)
            }
          )
          
          this.precioTag = ''
          this.precioValue = 1
          this.precioEstado = true
        }
      },
      eliminarPrecio: function (precioData, indice) {
        this.changeGlobalLoading(true)
        apiPlato.deletePrecio(precioData.id).then(
          (response) => {
            this.listaPrecios.splice(indice, 1)
            this.changeGlobalLoading(false)
          }
        ).catch(
          (error) => {
            this.changeGlobalLoading(false)
            console.log(error)
          }
        )
      },
      guardarDatosPrincipales: function () {
        if (!((this.platoName.length < 5 ) || (this.platoPrecio < 1))) {
          this.changeGlobalLoading(true)
          let data = {
            'name': this.platoName,
            'category': this.platoObject.category,
            'description': this.platoDescription,
            'orden': this.platoOrden,
            'price': this.platoPrecio,
          }
          apiPlato.updatePlato(data, this.platoObject.id).then(
            (response) => {
              this.changeGlobalLoading(false)
              this.finishMethodUpdate(response.data, this.categoryIndex, this.platoIndex, true)
            }
          ).catch(
            (error) => {
              this.changeGlobalLoading(false)
              console.log(error)
            }
          )
        } else {
          this.msjErrorMain = 'Ingrese Datos principales y precios correctamente'
        }
      },
      guardarDatosExtra: function () {
        this.changeGlobalLoading(true)
        let data = {
          'receta': this.platoReceta,
          'published': this.platoPublic,
        }
        apiPlato.updatePlato(data, this.platoObject.id).then(
          (response) => {
            this.changeGlobalLoading(false)
            this.finishMethodUpdate(response.data, this.categoryIndex, this.platoIndex, true)
          }
        ).catch(
          (error) => {
            this.changeGlobalLoading(false)
            console.log(error)
          }
        )
      },
    },
    beforeMount () {
      this.initDataPlato();
    },
  }
</script>

<style lang="scss" scoped src="./addPlato.scss">

</style>