import * as types from './mutation-types'

// accion para cambiar data modalidad
export const changeGlobalLoading = ({ commit }, flat) => {
  commit(types.SET_LOADING, flat)
}

export const showerGloblMsj = ({ commit }, mensaje) => {
  commit(types.SET_SHOWMSJ, {'flat': true, 'mensaje': mensaje})
  window.setTimeout(() => {
    commit(types.SET_SHOWMSJ,{'flat': false, 'mensaje': {bg: '#e53935', msj: 'saludos...'}})
  }, 3000)
}
