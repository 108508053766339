<template>
  <div ref="editor" class="editor-quill">

  </div>
</template>

<script>
  // trhee party apps
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'
  import Quill from 'quill' // install quill editor

  export default {
    name: 'AppEditorQuill',
    props: {
      content: String
    },
    methods: {
      inicializeEditor: function () {
        var toolbarOptions = [
          ['bold', 'italic', 'underline'],

          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'indent': '-1'}, { 'indent': '+1' }],
          [{ 'direction': 'rtl' }],

          [{ 'color': [] }, { 'background': [] }],
          [{ 'font': [] }],
          [{ 'align': [] }],

          ['clean']
        ]
        var options = {
          modules: {
            toolbar: toolbarOptions
          },
          theme: 'snow',
        }
        var editor = new Quill(this.$refs.editor, options)
        editor.pasteHTML(this.content)
        // escucha cambios
        editor.on('text-change', (delta, oldDelta, source) => {
          let html = this.$refs.editor.children[0].innerHTML
          const quill = editor
          const text = editor.getText()
          if (html === '<p><br></p>') html = ''
          // this._content = html
          // this.$emit('input', this._content)
          this.$emit('change', { html, text, quill })
        })
      },
    },
    mounted() {
      this.inicializeEditor()
    },
  }
</script>

<style lang="scss" scoped>
  .editor-quill {
    position: relative;
  }
</style>
