<template lang="html">
  <div class="card-flotain">
    <div class="card-flotain__content" :style="cssProps">
      <form class="card-flotain__body" method="POST" @submit.prevent="postMethod()">
        <h3 class="card-flotain__body__title">{{ title }}</h3>
        <slot>
          <!-- aqui va contenido formulario -->
        </slot>
        <div class="card-flotain__body__btns">
          <button v-if="showClose" @click="closeMethod()" type="button" class="card-flotain__body__btns__btn red">{{ closAction }}</button>
          <button v-if="isPost" v-bind="$attrs" class="card-flotain__body__btns__btn green">{{ postAction }}</button>
          <button v-if="isButton" @click="postMethod()" type="button" class="card-flotain__body__btns__btn green">{{ postAction }}</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VBaseCardFlotaing',
  props: {
      title: String,
      closAction: String,
      postAction: String,
      showClose: {
        type: Boolean, // si se muestra o no el boton cerrar
        default: true
      },
      isPost: {
        type: Boolean, // si el boton principal hara una peticion post
        default: true
      },
      isButton: {
        type: Boolean, // si el boton principal solo es un button
        default: false
      },
      closeMethod: {
        type: Function,
        default: function () {
          console.log('DS trabajando ...')
        }
      },
      postMethod: {
        type: Function,
        default: function () {
          console.log('DS trabajando ...')
        }
      },
      ancho: {
        type: Number,
        default: 30,
      }
    },
    computed: {
      cssProps() {
        return {
          '--large-width': this.ancho + "%",
        }
      }
    },
}
</script>

<style lang="scss" scoped >
.card-flotain {
    height: 100vh;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: 0px;
    background: rgba(0,0,0,0.2);
    z-index: 400;
    &__content {
      width: 90%;
      max-height: 95vh;
      overflow: auto;
      @include desde($large) {
        width: var(--large-width);
      }
    }
    &__body {
      background: $color-white;
      padding: 1.5em 1em;
      border-radius: 10px;
      @include desde ($medium) {
        padding: 2em;
      }
      &__title {
        color: $color-1;
        font-family: $ff-4;
        font-size: 16px;
        text-align: center;
        margin-bottom: 1em;
      }
      &__btns {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1em;
        &__btn {
          border: none;
          padding: 6px 10px;
          width: 100px;
          font-size: 14px;
          font-family: $ff-4;
          color: $color-white;
          &:hover {
            opacity: 0.7;
            cursor: pointer;
          }
          &.red {
            background: $color-7;
            margin: 7px;
          }
          &.green {
            background: $color-8;
            margin: 7px;
          }
        }
      }
    }
  }
</style>
