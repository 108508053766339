<template>
  <div class="app">
    <router-view />
    <app-loading v-if="globalLoading" />
    <mensaje-flotain
      v-if="showMensaje"
      :bg="globalMsj.bg"
      :msj="globalMsj.msj" />
  </div>
</template>
<script>
  // apps
  import AppLoading from '@/components/apps/AppLoading.vue'
  import MensajeFlotain from '@/components/apps/MensajeFlotain.vue'
  // vuex
  import {mapGetters} from 'vuex'

  export default {
    name: 'App',
    metaInfo: {
      title: 'Mi carta virtual online',
      titleTemplate: null,
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'Visita mi carta virutal',
      }],
      htmlAttrs: {
        lang: 'es-pe',
        amp: true
      },
    },
    components: {
      AppLoading,
      MensajeFlotain,
    },
    computed: {
      ...mapGetters({
        globalLoading: 'globalLoading',
        showMensaje: 'showMensaje',
        globalMsj: 'globalMsj'
      })
    }
  }
</script>
<style lang="scss">
  * {
    padding: -10px;
    margin: 0px;
  }
  html, body { height: 100%; overflow:auto;}
  table {
    border-collapse: collapse;
  }
</style>
