<template>
  <div class="template">
    <h4 class="template__title blue">Tema Seleccionado:</h4>
    <div v-if="temaSelected" class="template__selected">
      <div 
        class="templates__item">
          <img 
            :src="temaSelected.image"
            class="templates__item__img">
          <a href="#" class="templates__item__name">{{ temaSelected.name }}</a>
          <div class="templates__item__ctrls">
            <a href="#" class="templates__item__ctrls__link resaltado">Elegido</a>
            <a :href="temaSelected.video" target="_blank" class="templates__item__ctrls__link red">Ver</a>
          </div>
      </div>
    </div>
    <h4 class="template__title">Puedes elegir Otro Tema:</h4>
    <div class="templates">
      <div 
        class="templates__item" 
        v-for="tema in listaTemplates" 
        :key="tema.id">
          <img 
            :src="tema.image"
            class="templates__item__img">
          <a href="#" class="templates__item__name">{{ tema.name }}</a>
          <div class="templates__item__ctrls">
            <a href="#" @click="elegirTemplate(tema)" class="templates__item__ctrls__link">Elegir</a>
            <a :href="tema.video" target="_blank" class="templates__item__ctrls__link red">Ver</a>
          </div>
      </div>
    </div>
    <div class="footer">
      <a href="#" @click="guardarTemplate()" class="footer__btn">Guardar Nuevo Tema</a>
    </div>
  </div>
</template>

<script>
  // api
  import apiPlantilla from '@/api/Plantilla.js'
  import apiCarta from '@/api/Carta.js'
  // vuex
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'ListCartaTemplates',
    computed: {
      ...mapGetters({
        dataCartaTemplate: 'dataCartaTemplate',
        temaTempData: 'temaTempData'
      }),
    },
    data() {
      return {
        listaTemplates: [],
        temaSelected: null
      }
    },
    methods: {
      ...mapActions({
        changeGlobalLoading: 'changeGlobalLoading',
        setCartaTemplate: 'setCartaTemplate',
        setTemaTemp: 'setTemaTemp'
      }),
      initData: function () {
        this.temaSelected = this.dataCartaTemplate.template
      },
      toListTemplates: function () {
        this.changeGlobalLoading(true)
        apiPlantilla.listPlantillaAdmin().then(
          (response) => {
            this.changeGlobalLoading(false)
            this.listaTemplates = response.data
          }
        ).catch (
          (error) => {
            this.changeGlobalLoading(false)
          }
        )
      },
      elegirTemplate: function (tema) {
        this.temaSelected = tema
        this.dataCartaTemplate.template = tema
        let dataColoresTemp = {
          'colorBg': tema.color_bg,
          'colorPrimary': tema.color_primary,
          'colorSecondary': tema.color_secondary,
          'colorText': tema.color_text,
          'colorBorder': tema.color_border,
        }
        this.setTemaTemp(dataColoresTemp)
      },
      guardarTemplate: function () {
        if (this.temaTempData) {
          let dataFull = {
            'color_bg': this.temaTempData.colorBg,
            'color_primary': this.temaTempData.colorPrimary,
            'color_secondary': this.temaTempData.colorSecondary,
            'color_text': this.temaTempData.colorText,
            'color_border': this.temaTempData.colorBorder,
            'template_new': this.temaSelected.id,
            'carta_id': this.dataCartaTemplate.id,
          }
          this.changeGlobalLoading(true)
          apiCarta.saveTemaTemplateCartaUser(dataFull, this.dataCartaTemplate.id).then(
            (response) => {
              this.changeGlobalLoading(false)
              location.reload()
            }
          ).catch(
            (error) => {
              this.changeGlobalLoading(false)
            }
          )
        } else {
          let data = {
            'template': this.temaSelected.id
          }
          this.changeGlobalLoading(true)
          apiCarta.updateCartaUser(data, this.dataCartaTemplate.id).then(
            (response) => {
              this.changeGlobalLoading(false)
              location.reload()
            }
          ).catch(
            (error) => {
              this.changeGlobalLoading(false)
            }
          )
        }
      }
    },
    beforeMount () {
      this.toListTemplates()
      this.initData()
    },
  }
</script>

<style lang="scss" scoped>
  .template {
    &__title {
      font-family: $ff-4;
      font-size: 16px;
      color: $color-4;
      &.blue {
        color: $color-10;
      }
    }
    &__selected {
      margin-bottom: 1em;
    }
  }
  .templates {
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    &__item {
      border: 1px solid $color-4;
      border-radius: 5px;
      display: block;
      width: 44%;
      margin: 5px;
      background: $color-3;
      @include desde($medium) {
        width: 30%;
      };
      &__img {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        width: 100%;
        height: 100px;
        margin: 0px;
      }
      &__name {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: $ff-4;
        text-decoration: none;
        color: $color-4;
        height: 35px;
        font-size: 16px;
        text-transform: capitalize;
      }
      &__ctrls {
        padding: 5px;
        &__link {
          display: block;
          text-decoration: none;
          background: $color-8;
          color: $color-white;
          text-align: center;
          margin-top: 5px;
          padding: 3px;
          font-size: 14px;
          font-family: $ff-1;
          border-radius: 5px;
          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }
          &.red {
            background: $color-7;
          }
          &.resaltado {
            background: $color-2;
          }
        }
      }
    }
  }
  .footer {
    margin-top: 1em;
    margin-bottom: 2em;
    padding: 10px;
    width: 80%;
    &__btn {
      display: block;
      background: #3867d6;
      color: $color-white;
      padding: 10px;
      text-align: center;
      text-decoration: none;
      font-family: $ff-4;
      border-radius: 10px;
      &:hover {
        opacity: 0.6;
        color: $color-11;
      }
    }
  }
</style>