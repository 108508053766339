// services for user api
import axios from 'axios'
// import global settings
import baseConf from '@/Base'

var instance = axios.create({
  baseURL: baseConf.urlApi,
  timeout: 3000
})


const apiUsers = {
  loginUser: function (data) {
    return instance.post('/login-user/', data)
  },
  userRetrieve: function () {
    var header = {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    }
    return instance.get('/user/get/', header)
  },
  userUpdateData: function (data) {
    return instance.patch('/user/update/', data, {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    })
  }
}

export default apiUsers
