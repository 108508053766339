<template>
  <VBaseCardFlotaing
    :title="productoObj.name"
    closAction="Cerrar" 
    :isPost="false"
    :closeMethod="closeFunction" >

    <div class="detail">
      <fieldset class="detail__fieldset">
        <legend class="detail__title">
          <icono
            icon="mdiCashMultiple"
            :size="30"
            class="detail__title__icon"
            ></icono>  
          Precios</legend>
        <div class="detail__precios" >
        <div class="detail__precios__table" v-for="precio in productoObj.precios" :key=" precio.id">
          <p class="detail__precios__table__size">{{ precio.tag }} </p> 
          <p class="detail__precios__table__price"> {{ precio.value }} </p> 
        </div>
      </div>
      </fieldset>      
      <div class="detail__img">
        <img 
          :src="getImageProducto(productoObj.image_one)" 
          @click="seleccionarImg(productoObj.image_one)"
          class="detail__img__imagen">       
        <img 
          :src="getImageProducto(productoObj.image_two)" 
          @click="seleccionarImg(productoObj.image_two)"
          class="detail__img__imagen">       
        <img 
          :src="getImageProducto(productoObj.image_three)" 
          @click="seleccionarImg(productoObj.image_three)"
          class="detail__img__imagen">       
      </div>
      <div class="detail__open">
        <img :src="getImageProducto(imageSelected)" class="detail__open__img">
      </div>      
      <div class="detail__receta">
        <fieldset>
          <legend class="detail__receta__title">
            <icono
            icon="mdiChefHat"
            :size="30"
            class="detail__receta__title__icon"
            ></icono>            
            Mas Detalles del Producto:
          </legend>
          <p v-if="productoObj.receta.length > 3" class="detail__receta__description" v-html="productoObj.receta">
            
          </p>
          <p v-else class="detail__receta__description">
            {{ productoObj.description }}
          </p>
        </fieldset>        
      </div>       
    </div>    
  </VBaseCardFlotaing> 
</template>

<script>
  //
  import bannerImg from '@/assets/fondo.jpg'
  //
  import VBaseCardFlotaing from '@/components/base/VBaseCardFlotaing.vue'

  export default {
    props: {
      productoObj: Object,
      closeFunction: {
        type: Function,
      },
    },
    components: {
      VBaseCardFlotaing,
    },
    data() {
      return {
        imageSelected: null
      }
    },
    methods: {
      initData: function () {
        if (this.productoObj.image_one) {
          this.imageSelected = this.productoObj.image_one
        } else {
          this.imageSelected = bannerImg
        }
      },
      getImageProducto: function (imagen) {
        if (imagen) {
          return imagen
        } else {
          return bannerImg
        }
      },
      seleccionarImg: function (imagen) {
        this.imageSelected = imagen
      }
    },
    beforeMount () {
      this.initData()
    },
  }
</script>

<style lang="scss" scoped>
  .detail {    
    max-height: 60vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    &__fieldset {
      border: solid $color-4 2px;   
      width: 80%;   
    }
    &__title {
      display: flex;
      justify-content: flex-start;
      text-align: left;
      align-items: center;
      &__icon {
        color: $color-8;
      }
    }
    &__precios {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      &__table {
        width: 40%;
        align-items: center;
        text-align: center; 
        line-height: 12px;
        margin: 0.2em 0.1em;
        border-bottom: solid $color-2 2px;
        border-right: solid $color-2;
        border-radius: 10px ;
        padding-bottom: 5px;
        @include desde ($large) {
          width: 30%;
        }  
        &__size {
          margin: 5px;
          font-family: $ff-7;
          font-weight: 800;
          font-size: 12px;
          
        }
        &__price {
          color: $color-black;
          font-family: $ff-4;
          font-weight: 800;
        }
        
      }
    }
    
    &__img {
      display: flex;
      justify-content: flex-start;
      &__imagen {
        display: block;
        display: flex;
        width: 50px;
        height: 50px;
        margin: 2px;
        &:hover {
          cursor: pointer;
          opacity: 0.5;
        }
      }
    }
    &__open {
      width: 100%;
      &__img {
        width: 100%;
        max-height: 250px;
        @include desde ($large) {
          max-height: 350px;
        }
      }
    }
    &__receta {  
      width: 100%;  
      text-align: left;  
      &__title {
        display: flex;
        align-items: center;
        justify-content: flex-start;        
        &__icon {
          color: $color-black;
        }
      }
      &__description {
        text-align: justify;
      }

    }
  }

</style>