<template lang="html">
  <div class="build-carta">
    <h3 class="build-carta__title">Comienza a construir tu Carta</h3>
    <div class="build-carta__ctrls">
      <router-link 
        :to="{name: 'theme-carta', params: { idCarta: $route.params.idCarta }}" 
        class="build-carta__ctrls__link">
          Cambiar Diseño
      </router-link>
      <a
        v-if="userData"  
        :href="urlBase + '/' + userData.urlname + '/'" 
        target="_blank"
        class="build-carta__ctrls__link green">Vista Previa</a>
    </div>
    <form class="build-carta__form" method="POST" @submit.prevent="addCategoriaCarta()">
      <p class="build-carta__form__title" title="Agrega Grupo de platos">Agrega una Categoría</p>
      <p class="build-carta__form__subtext">Así podrás agrupar productos por categorías</p>
      <div class="input-form" style="width: 100%;">
        <p class="input-form__lbl">Nombre Categoría:</p>
        <input
          type="text"
          class="input-form__in"
          placeholder="Platos Tipicos..."
          v-model="name">
      </div>
      <div class="input-form" style="width: 100%;">
        <p class="input-form__lbl">Orden (en que orden aparecerá):</p>
        <input
          type="number"
          class="input-form__in"
          placeholder="0"
          style="width: 200px;"
          v-model="orden">
      </div>
      <p class="build-carta__form__error">{{ errorMsjAdd }}</p>
      <button type="submit" class="build-carta__form__btn">Agregar Categoría</button>
    </form>
    <div class="carta">
      <p class="carta__title">Datos de la Carta:</p>
      <div class="carta__item" v-for="(categoria, index) in listaCategorias" :key="categoria.id">
        <div class="carta__item__category">
          <p class="carta__item__category__name">
            <span> {{ categoria.orden }} - {{ categoria.name }}</span>
            <a href="#" @click="showEdit(categoria)" class="carta__item__category__name__icon">
              <icono
                icon="mdiGreasePencil" 
                :size="15"></icono>
            </a>
          </p>
          <a href="#" @click="showerAddPlato(categoria, index)" class="carta__item__category__btn">Agregar Producto</a>
        </div>
        <div class="carta__item__plato" v-for="(plato, indice) in categoria.platos" :key="plato.id">
          <div class="carta__item__plato__info">
            <p class="carta__item__plato__info__name" :class="{tachadoClass: !plato.published}">{{ plato.name }}</p>
            <p class="carta__item__plato__info__price">{{ plato.price }}</p>
          </div>
          <div class="carta__item__plato__btns">
            <a href="#" @click="showerEditPlato(plato, index, indice)" class="carta__item__plato__btns__btn">Editar</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <edit-category-carta 
    v-if="showEditCategory"
    :closeEditCategory="closeEditCategory"
    :categoryObject="categorySelected"
    :finishMethodUpdate="actualziarCategoria"
    :finishMethodDelete="quitarCategoria"
  />
  <add-plato-carta-admin 
    v-if="showAddPlato"
    :closeAddPlatoCategory="cerrarAddPlato"
    :categoryObject="categoryPlatoSelected"
    :categoryIndex="categoriaIndexSelected"
    :finishMethodSave="agregarPlatoCarta"
  />
  <edit-plato-carta-admin 
    v-if="showEditPlato"
    :plato-object="platoSelected"
    :platoIndex="platoIndexSelected"
    :categoryIndex="categoriaIndexSelected"
    :closeEditPlato="cerrarEditarPlato"
    :finishMethodUpdate="actualizarDatosPlato"
  />
</template>

<script>
  import baseConf from '@/Base'
  import apiPlato from '@/api/Plato.js'
  // vuex
  import { mapActions, mapGetters } from 'vuex'
  // components
  import EditCategoryCarta from './EditCategoryCarta.vue'
  import AddPlatoCartaAdmin from './AddPlatoCartaAdmin.vue'
  import EditPlatoCartaAdmin from './EditPlatoCartaAdmin.vue'


  export default {
    name: 'BuildCarta',
    components: {
      EditCategoryCarta,
      AddPlatoCartaAdmin,
      EditPlatoCartaAdmin
    },
    computed: {
      ...mapGetters({
        userData: 'userData',
      })
    },
    data () {
      return {
        urlBase: baseConf.baseDomain,
        name: '',
        orden: 0,
        errorMsjAdd: '',
        listaCategorias: [],
        showEditCategory: false,
        showAddPlato: false,
        categorySelected: null,
        categoryPlatoSelected: null,
        categoriaIndexSelected: -1,
        showEditPlato: false,
        platoSelected: null,
        platoIndexSelected: -1,
      }
    },
    methods: {
      ...mapActions({
        changeGlobalLoading: 'changeGlobalLoading'
      }),
      toListCategories: function () {
        this.changeGlobalLoading(true)
        apiPlato.listCategoriesCarta(this.$route.params.idCarta).then(
          (response) => {
            this.listaCategorias = response.data
            this.changeGlobalLoading(false)
          }
        ).catch (
          (error) => {
            this.changeGlobalLoading(false)
            console.log(error)
          }
        )
      },
      validateData: function () {
        this.errorMsjAdd = ''
        if ((this.name.length < 5 ) || (this.orden < 0 )) {
          this.errorMsjAdd = 'Error: Agregue una nombre de categoria valido'
          return false
        } else {
          this.errorMsjAdd = ''
          return true
        }
      },
      addCategoriaCarta: function () {
        if (this.validateData()) {
          let data = {
            'name': this.name,
            'carta': this.$route.params.idCarta,
            'orden': this.orden,
          }
          this.changeGlobalLoading(true)
          apiPlato.addCategoryCarta(data).then(
            (response) => {
              this.changeGlobalLoading(false)
              let categoria = {
                'id': response.data.id,
                'name': response.data.name,
                'carta':response.data.carta,
                'published': true,
                'orden': response.data.orden,
                'platos':[],
              }
              this.listaCategorias.push(categoria)
              this.name = ''
            }
          ).catch(
            (error) => {
              this.changeGlobalLoading(false)
              console.log(error)
            }
          )
        }
      },
      closeEditCategory: function () {
        this.categorySelected = null
        this.showEditCategory = false
      },
      showEdit: function (data) {
        this.categorySelected = data 
        this.showEditCategory = true
      },
      actualziarCategoria: function (data) {
        this.categorySelected.name = data.name
        this.categorySelected.orden = data.orden
        this.categorySelected.image = data.image
        this.showEditCategory = false
      },
      quitarCategoria: function (data) {
        location.reload()
        this.showEditCategory = false
      },
      agregarPlatoCarta: function (plato, index) {
        this.listaCategorias[index].platos.push(plato)
        this.showAddPlato = false
        this.categoryPlatoSelected = null
        this.categoriaIndexSelected = -1
      },
      showerAddPlato: function (data, index) {
        this.categoryPlatoSelected = data
        this.categoriaIndexSelected = index
        this.showEditCategory = false
        this.showAddPlato = true
      },
      cerrarAddPlato: function () {
        console.log('cerrando add plato...')
        this.showAddPlato = false
      },
      showerEditPlato: function (plato, indexCategory, indexPlato) {
        console.log('editar plato')
        this.platoSelected = plato
        this.platoIndexSelected = indexPlato
        this.categoriaIndexSelected = indexCategory
        this.showEditPlato = true
      },
      cerrarEditarPlato: function () {
        this.showEditPlato = false
        this.platoSelected = null
        this.categoriaIndexSelected = -1
        this.platoIndexSelected = -1
      },
      actualizarDatosPlato: function (data, indexCategory, indexPlato, closeFlat=true) {
        this.listaCategorias[indexCategory].platos[indexPlato] = data
        if (closeFlat) {
          this.cerrarEditarPlato()
        }
      }
    },
    beforeMount () {
      this.toListCategories();
    },
  }
</script>

<style lang="scss" scoped >
  .build-carta {
    padding: 10px 3px;
    @include desde($large) {
      padding: 1em 4em;
    }
    &__title {
      font-family: $ff-3;
      text-align: center;
      color: $color-4;
      margin-bottom: 10px;
      text-transform: capitalize;
      font-weight: bold;
      font-size: 22px;
      @include desde($large) {
        font-size: 25px;
      }
    }
    &__ctrls {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      &__link {
        background: $color-4;
        color: $color-white;
        text-decoration: none;
        margin: 5px;
        width: 150px;
        padding: 7px;
        text-align: center;
        font-family: $ff-4;
        border-radius: 5px;
        &.green {
          background: $color-8;
        }
      }
    }
    &__form {
      border: 1px solid $color-12;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &__title {
        font-family: $ff-3;
        font-size: 14px;
        color: $color-5;
        text-align: center;
        font-weight: bold;
        text-decoration: underline;
        margin-bottom: 10px;
        @include desde($large) {
          font-size: 18px;
        }
      }
      &__subtext {
        font-size: 13px;
        color: $color-10;
        font-family: $ff-3;
      }
      &__error {
        font-family: $ff-3;
        color: $color-6;
        text-align: left;
        width: 100%;
      }
      &__btn {
        border: none;
        background: $color-10;
        color: $color-white;
        font-family: $ff-4;
        padding: 7px;
        width: 100%;
        @include desde($large) {
          font-size: 18px;
          width: 50%;
          border-radius: 10px;
        }
        &:hover {
          cursor: pointer;
          opacity: 0.6;
        }
      }
    }
  }
  .carta {
    &__title {
      font-family: $ff-5;
      font-size: 14px;
      color: $color-5;
      text-align: center;
      font-weight: bold;
      margin-bottom: 10px;
      @include desde($large) {
        font-size: 18px;
      }
    }
    &__item {
      margin-bottom: 1em;
      &__category {
        border-bottom: 1px solid;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        color: $color-5;
        &__name {
          display: flex;
          align-items: center;
          font-family: $ff-4;
          color: $color-5;
          font-size: 14px;
          font-weight: bold;
          @include desde($medium) {
            font-size: 16px;
          }
          &__icon {
            border: 1px solid;
            padding: 5px;
            margin-left: 10px;
            color: $color-8;
            &:hover {
              cursor: pointer;
            }
          }
        }
        &__btn {
          background: $color-4;
          color: $color-white;
          font-size: 13px;
          font-family: $ff-4;
          padding: 5px;
          text-decoration: none;
          width: 100px;
          text-align: center;
          @include desde($medium) {
            font-size: 14px;
            width: 120px;
          }
          &:hover {
            cursor: pointer;
            opacity: 0.6;
          }
        }
      }
      &__plato {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 0px;
        &__info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 85%;
          &__name {
            font-family: $ff-4;
            font-size: 14px;
            text-transform: capitalize;
            @include desde($medium) {
              font-size: 16px;
            }
          }
          &__price {
            font-family: $ff-3;
            font-size: 13px;
            font-weight: bold;
            width: 80px;
            text-align: left;
            @include desde($medium) {
              font-size: 16px;
            }
          }
        }
        &__btns {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 15%;
          text-align: center;
          &__btn {
            text-decoration: none;
            font-size: 14px;
            border: 1px solid;
            padding: 3px;
            color: $color-10;
            cursor: pointer;
            @include desde($medium) {
              font-size: 16px;
              padding: 5px 10px;
            }
          }
        }
      }
    }
  }
  .tachadoClass {
    text-decoration: line-through;
  }
</style>
