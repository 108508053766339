// services for user api
import axios from 'axios'
// import global settings
import baseConf from '@/Base'

var instance = axios.create({
  baseURL: baseConf.urlApi,
  timeout: 3000
})

const apiCarta = {
  cartasUser: function (page) {
    //
    return instance.get('/api/carta/by-user/?page=' + page, {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    })
  },
  addCartaUser: function (data) {
    //
    return instance.post('/api/carta/add/', data, {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    })
  },
  getCartaUserAdmin: function (pk) {
    //
    return instance.get('/api/carta/get-admin/' + pk + '/', {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    })
  },
  updateCartaUser: function (data, pk) {
    //
    return instance.patch('/api/carta/update-admin/' + pk + '/', data, {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  saveTemaTemplateCartaUser: function (data) {
    //
    return instance.post('/api/carta/save-tema-template/', data, {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    })
  },
  getCartaUsername: function (username) {
    //
    return instance.get('/api/carta/get-username/' + username + '/',)
  },
}

export default apiCarta
