<template>
  <VBaseCardFlotaing
    :title="'Eliminar Carta: ' + cartaObj.name"
    closAction="Cerrar"
    postAction="Guardar"
    :isButton="false"
    :closeMethod="closeFunction"
    :postMethod="modificarDatos"
  >
    <div class="form-edit">
      <div class="input-form">
        <p class="input-form__lbl">Nombre:</p>
        <input
          type="text"
          class="input-form__in"
          placeholder="mi primera carta"
          v-model="name">
      </div>
      <div class="input-form">
        <p class="input-form__lbl">Cambiar Logo:</p>
        <div class="image-content">
          <input
            type="file"
            class="input-form__in"
            @change="getImageLogo">
          <img v-if="cartaObj.image" :src="cartaObj.image" class="image-content__img">
        </div>
      </div>
      <div class="input-form">
        <p class="input-form__lbl">WhatsApp::</p>
        <input
          type="text"
          class="input-form__in"
          placeholder="+51568952354"
          v-model="phone">
      </div>
      <div class="input-form">
        <p class="input-form__lbl">URL Web, Facebook U Otro:</p>
        <input
          type="url"
          class="input-form__in"
          placeholder="https://www.facebook.com/Bingeres-100770498979461"
          v-model="web">
      </div>
      <p class="text-error" v-if="msjError.length > 0">{{ msjError }}</p>
    </div>
    <a href="#" @click="showDelete=!showDelete" class="form-delete__link">Eliminar Carta</a>
    <div v-if="showDelete" class="form-delete">
      <div class="input-form">
        <p class="input-form__lbl">Escribe: ELIMINAR</p>
        <input
          type="text"
          class="input-form__in"
          placeholder="escribe aqui"
          v-model="deleteForm">
      </div>
      <p class="form-delete__alert">{{ msjEliminar }}</p>
      <a href="#" class="form-delete__btn" @click="eliminarCarta()">Continuar a Eliminar</a>
    </div>
  </VBaseCardFlotaing>
</template>

<script>
  //
  import apiCarta from '@/api/Carta.js'
  // vuex
  import { mapActions } from 'vuex'
  // mixin
  import { resizeImageMixin } from '@/mixins/resizeImageMixin.js'
  // components
  import VBaseCardFlotaing from '@/components/base/VBaseCardFlotaing.vue'

  export default {
    name: 'EditFormCarta',
    mixins: [resizeImageMixin],
    props: {
      cartaObj: {
        type: Object,
        default: null
      },
      closeFunction: Function,
    },
    components: {
      VBaseCardFlotaing,
    },
    data() {
      return {
        name: '',
        phone: '',
        web: null,
        deleteForm: '',
        showDelete: false,
        msjEliminar: '',
        imageLogo: null,
        msjError: '',
      }
    },
    methods: {
      ...mapActions({
        changeGlobalLoading: 'changeGlobalLoading'
      }),
      getImageLogo (event) {
        this.changeGlobalLoading(true)
        this.optimizePhoto(event.target.files[0]).then(
          (response) => {
            this.changeGlobalLoading(false)
            this.imageLogo = response
          }
        )
      },
      initData: function () {
        this.name = this.cartaObj.name
        this.phone = this.cartaObj.phone
        this.web = this.cartaObj.fanpage
        this.msjEliminar = ''
      },
      eliminarCarta: function () {
        this.msjEliminar = ''
        if (this.deleteForm === 'ELIMINAR') {
          this.changeGlobalLoading(true)
          var data = new FormData()
          data.append('anulate', true)
          data.append('published', false)
          data.append('activo', false)
          apiCarta.updateCartaUser(data, this.cartaObj.id).then(
            (response) => {
              location.reload()
              this.changeGlobalLoading(false)
            }
          ).catch (
            (error) => {
              this.changeGlobalLoading(false)
              console.log(error)
            }
          ) 
        } else {
          this.msjEliminar = 'No se puede Eliminar'
        }
      },
      modificarDatos: function () {
        var data = new FormData()
        if (this.imageLogo) {
          data.append('image', this.imageLogo) 
        }
        if (this.name.length > 3 ) {
          data.append('name', this.name)
          data.append('phone', this.phone)
          data.append('fanpage', this.web)
          this.changeGlobalLoading(true)
          apiCarta.updateCartaUser(data, this.cartaObj.id).then(
            (response) => {
              this.cartaObj.name = response.data.name
              this.cartaObj.phone = response.data.phone
              this.cartaObj.fanpage = response.data.fanpage
              //
              this.closeFunction()
              this.changeGlobalLoading(false)
            }
          ).catch (
            (error) => {
              console.log(error)
              this.msjError = 'Completa los datos correctamente'
              this.changeGlobalLoading(false)
            }
          )
        }
      }
    },
    beforeMount () {
      this.initData();
    },
  }
</script>

<style lang="scss" scoped>
  .form-delete {
    &__link {
      color: $color-6;
      font-family: $ff-4;
      font-size: 16px;
    }
    &__btn {
      background: $color-6;
      color: $color-3;
      font-family: $ff-4;
      border: none;
      padding: 5px 10px;
      &:hover {
        cursor: pointer;
      }
    }
    &__alert {
      font-family: $ff-3;
      color: $color-6;
      font-size: 14px;
      margin-bottom: 2em;
    }
  }
  .image-content {
    display: flex;
    align-items: center;
    &__img {
      width: 60px;
      height: 60px;
    }
  }
</style>