// funciones reutilizables para imagenes

export const resizeImageMixin = {
  methods: {
    readPhoto: async function (photo) {
      const canvas = document.createElement('canvas')
      const img = document.createElement('img')
    
      // create img element from File object
      img.src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = (e) => resolve(e.target.result)
        reader.readAsDataURL(photo)
      })
      await new Promise((resolve) => {
        img.onload = resolve
      })
    
      // draw image in canvas element
      canvas.width = img.width
      canvas.height = img.height
      canvas.getContext('2d').drawImage(img, 0, 0, canvas.width, canvas.height)
    
      return canvas
    },
    scaleCanvas: (canvas, scale) => {
      const scaledCanvas = document.createElement('canvas');
      scaledCanvas.width = canvas.width * scale;
      scaledCanvas.height = canvas.height * scale;
    
      scaledCanvas
        .getContext('2d')
        .drawImage(canvas, 0, 0, scaledCanvas.width, scaledCanvas.height);
    
      return scaledCanvas;
    },
    dataURLToBlob: function(dataURL) {
      var BASE64_MARKER = ';base64,';
      if (dataURL.indexOf(BASE64_MARKER) == -1) {
          var parts = dataURL.split(',');
          var contentType = parts[0].split(':')[1];
          var raw = parts[1];
  
          return new Blob([raw], {type: contentType});
      }
  
      var parts = dataURL.split(BASE64_MARKER);
      var contentType = parts[0].split(':')[1];
      var raw = window.atob(parts[1]);
      var rawLength = raw.length;
  
      var uInt8Array = new Uint8Array(rawLength);
  
      for (var i = 0; i < rawLength; ++i) {
          uInt8Array[i] = raw.charCodeAt(i);
      }
  
      let blob = new Blob([uInt8Array], {type: contentType});
      return new File([blob], "image.jpeg")
  },
  optimizePhoto: async function (photo) {
      let canvas = await this.readPhoto(photo)
      while ((canvas.width*canvas.height) > 2073600) {
        console.log('size: ', canvas.width*canvas.height)
        canvas = this.scaleCanvas(canvas, .5);
      }
      return this.dataURLToBlob(canvas.toDataURL("image/jpeg", 0.5))
    },
  },
}