<template>
  <div class="carta-galery"  :style="{background: getColorBackground(temaObj.color_bg)}">
    <div class="carta-galery__head">
      <div class="carta-galery__head__left" :style="{borderLeftColor: getColorBorder(temaObj.color_border)}">
        <h1 class="carta-galery__head__left__title" :style="{color: getColorPrimary(temaObj.color_primary)}">{{ temaObj.title }} </h1>
        <p 
          class="carta-galery__head__left__text" 
          :style="{color: getColorSecondary(temaObj.color_secondary)}">
          {{ temaObj.subtitle }}</p>
      </div>
      <div class="carta-galery__head__right">
        <p class="carta-galery__head__right__phone">
          <icono
            icon="mdiWhatsapp"
            :size="30"
            class="carta-galery__head__right__phone__icono"
            :style="{color: getColorText(temaObj.color_text)}"
          ></icono>
          <span class="carta-galery__head__right__phone__text" :style="{color: getColorText(temaObj.color_text)}">{{ temaObj.text_pie }}</span>
        </p>
      </div>
    </div>

    <!-- aqui va el menu de filtros corto -->
    
    <div class="carta-galery__body">
      <div 
        class="carta-galery__body__item" 
        v-for="categoria in listPlatos" :key="categoria.id">
          <p class="carta-galery__body__item__title" :style="{color: getColorSecondary(temaObj.color_secondary)}">{{ categoria.name }}</p>
          <div 
            class="carta-galery__body__item__grid">
              <div class="carta-galery-product"
              v-for="producto in categoria.platos" :key="producto.id">
                <img
                  :src="getImageProduct(producto)"
                  @error="setAltImg" 
                  class="carta-galery-product__img">
                <div class="carta-galery-product__body">
                  <p class="carta-galery-product__body__title" :style="{color: getColorText(temaObj.color_text)}">{{ producto.name }}</p>
                  <p class="carta-galery-product__body__price" :style="{color: getColorText(temaObj.color_text)}">
                    {{ getEtiquetaPrecioMain(producto.precios).value }}</p>
                  <a 
                    href="#"
                    @click="openDetail(producto)"
                    target="_blank"
                    class="carta-galery-product__body__btn"
                    :style="{background: getColorPrimary(temaObj.color_primary), color: getColorBackground(temaObj.color_bg)}">Ver Más</a>
                </div>
              </div>
          </div>
      </div>
    </div>

    <!-- pie de pagina -->
    <div class="carta-galery__footer">
      <p 
        class="carta-galery__footer__title"
        :style="{color: getColorPrimary(temaObj.color_primary)}">{{ temaObj.title_pie }}</p>
      <a
        :href="dataCartaTemplate.fanpage" 
        class="carta-galery__footer__link"
        target="_blank"
        :style="{color: getColorText(temaObj.color_text)}">{{ temaObj.text_link }}</a>
      <a 
        href="#" 
        class="carta-galery__footer__pie"
        :style="{color: getColorText(temaObj.color_text)}">{{ temaObj.text_pie }}</a>
    </div>
  </div>
  <base-detail-template 
      v-if="showDetail"
      :productoObj="productoSelected"
      :closeFunction="closeDetail"
    >
  </base-detail-template>
</template>

<script>
  // vuex
  import { mapGetters } from 'vuex'
  // mixins
  import { getColorsTemplateMixin } from '@/mixins/getColorsTemplateMixin.js'
  // mixins
  import { notImageMixin } from '@/mixins/notImageMixin.js'
  import { cartaMixin } from '@/mixins/cartaMixin.js'
  
  import BaseDetailTemplate from './BaseDetailTemplate.vue'
  
  export default {
    name: 'BaseGaleryTemplate',
    mixins: [cartaMixin, getColorsTemplateMixin, notImageMixin,],
    props: {
      temaObj: {
        type: Object,
        default: null 
      },
    },
    components: {
      BaseDetailTemplate,
    },
    computed: {
      ...mapGetters({
        listPlatos: 'listPlatos',
        dataCartaTemplate: 'dataCartaTemplate',
      }),
    },
    data() {
      return {
        productoSelected: null,
        showDetail: false
      }
    },
    methods: {
      
    },
  }
</script>

<style lang="scss" scoped>
  .carta-galery {
    background: #ecf0f1;
    &__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1em;
      box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.4);
      padding: 10px;
      @include desde ($large) {
        padding: 1em 3em;
      }
      &__left {
        text-align: center;
        width: 200px;
        display: block;
        border-left: 2px solid #e67e22;
        &__title {
          font-size: 30px;
          font-family: $ff-11;
          color: #e67e22;
          margin-bottom: 5px;
          text-transform: capitalize;
          font-weight: bold;
          max-height: 60px;
        }
        &__text {
          font-size: 10px;
          color: #e67e22;
          font-family: $ff-3;
          text-transform: uppercase;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &__right {
        &__phone {
          display: flex;
          align-items: center;
          &__text {
            display: block;
            margin-left: 10px;
            font-weight: bold;
          }
        }
      }
    }
    &__body {
      padding: 10px;
      @include desde ($large) {
        padding: 1em 3em;
      }
      &__item {
        margin-bottom: 1em;
        &__title {
          font-family: $ff-4;
          font-weight: bold;
          margin-bottom: 15px;
          font-size: 20px;
          color: #e74c3c;
          text-transform: capitalize;
        }
        &__grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 14px;
          margin-bottom: 2em;
          @include desde ($medium) {
            grid-template-columns: 1fr 1fr 1fr;
          }
          @include desde ($large) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
          }
        }
      }
    }
  }
  .carta-galery-product {
    width: 100%;
    height: 300px;
    box-shadow: 1px 3px 5px 0px rgba(189,195,199,0.75);
    border-radius: 5px;
    &__img {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      width: 100%;
      height: 160px;
    }
    &__body {
      padding-top: 10px;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      overflow: hidden;
      &__title {
        font-family: $ff-4;
        font-size: 17px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 10px;
        max-height: 40px;
        overflow: hidden;
      }
      &__price {
        font-family: $ff-3;
        font-size: 20px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 10px;
      }
      &__btn {
        background: #e67e22;
        color: #ecf0f1;
        padding: 5px 1em;
        text-decoration: none;
        border-radius: 5px;
        font-family: $ff-1;
      }
    }
  }

  .carta-galery__footer {
    padding: 10px;
    @include desde ($large) {
      padding: 1em 3em;
    }
    &__title {
      font-family: $ff-11;
      font-size: 16px;
    }
    &__link {
      display: block;
      font-family: $ff-3;
      text-decoration: none;
      color: #2d3436;
    }
    &__pie {
      font-family: $ff-5;
      text-decoration: none;
      font-weight: bold;
      color: #2d3436;
    }
  }
</style>