<template>
  <div v-if="userData" class="qr-user">
    <p class="qr-user__title">Guarda la Imagen QR para tus clientes: </p>
    <div class="qr-user__body" ref="capture">
      <div class="qr-user__body__capture">
        <!-- <qrcode-vue 
          :margin='0'
          :renderAs="renderAs"
          :size="size"
          :value="urlBase + '/' + userData.urlname + '/'"></qrcode-vue> -->
        
        <img 
          class="qr-user__body__capture__img"
          :src="getQrUser()" />
      </div>
      <p class="qr-user__body__link">
        <span class="qr-user__body__link__title">Escane el codigo QR o accede a:</span>
        <a 
          :href="urlBase + '/' + userData.urlname + '/'"
          class="qr-user__body__link__url"
        >{{ urlBase + '/' + userData.urlname + '/' }}</a>
      </p>
    </div>
    <div class="qr-user__ctrls">
      <button @click="downloadCard()" class="qr-user__ctrls__btn">Descargar</button>
    </div>
  </div>
</template>

<script>

  import baseConf from '@/Base'
  import QrcodeVue from 'qrcode.vue'
  //
  import { canvasMixins } from '@/mixins/canvasMixins'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'QrUser',
    mixins: [canvasMixins],
    components: {
      QrcodeVue,
    },
    computed: {
      ...mapGetters({
        isLoggedIn: 'isLoggedIn',
        userData: 'userData',
      })
    },
    data() {
      return {
        urlBase: baseConf.baseDomain,
        size: 150,
        margin: 0,
        renderAs: 'canvas',
      }
    },
    methods: {
      getQrUser: function () {
        let urlUser = baseConf.baseDomain + '/' + this.userData.urlname + '/'
        let baseurlqr = 'https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl='+ urlUser +'&choe=UTF-8'
        return baseurlqr
      },
      downloadCard: function () {
        // llamamos a la funcion del mixin
        this.downloadDivHtlml(this.$refs.capture)
      }
    },
  }
</script>

<style lang="scss" scoped>
  .qr-user {
    &__title {
      font-family: $color-10;
      font-size: 18px;
      font-family: $ff-3;
      margin-bottom: 1em;
      margin-top: 1em;
      display: block;
      color: $color-10;
      font-weight: bold;
    }
    &__body {
      padding: 2em 1em;
      width: 100%;
      height: 100%;
      border: $color-5 1px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &__capture {
        display: flex;
        justify-content: center;
        align-items: center;
        &__img {
          border: $color-5 solid 1px;
        }
      }
      &__link {
        text-align: center;
        &__title {
          font-size: 15px;
          margin-bottom: 10px;
          color: $color-10;
          display: block;
          font-family: $ff-4;
        }
        &__url {
          color: $color-5;
          text-decoration: none;
          font-family: sans-serif;
          &:hover {
            cursor: pointer;
            color: $color-4;
          }
        }
      }
    }
    &__ctrls {
      display: flex;
      justify-content: center;
      &__btn {
        @include hasta($large) {
          display: none;
        };
        margin-top: 10px;
        background: $color-8;
        color: $color-3;
        border: none;
        padding: 7px 2em;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
</style>