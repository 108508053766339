<template>
  <div class="form-tema">
    <div class="form-tema__forms" v-if="dataCartaTemplate">
      <div class="input-form">
        <p class="input-form__lbl">Título:</p>
        <input
          type="text"
          class="input-form__in"
          placeholder="nombre de la empresa"
          v-model="titleTema">
      </div>
      <div class="input-form">
        <p class="input-form__lbl">Sub título:</p>
        <input
          type="text"
          class="input-form__in"
          placeholder="nombre de la empresa"
          v-model="subtitleTema">
      </div>
      <div class="form-tema__forms__main">
        <a href="#" @click="showprodTema=!showprodTema" class="form-tema__forms__main__action">Mostrar Promoción o Especial</a>
        <div v-if="showprodTema" class="form-tema__forms__main__plato">
          <div class="input-form">
            <p class="input-form__lbl">Nombre Producto Principal:</p>
            <input
              type="text"
              class="input-form__in"
              placeholder="Pollo a la plancha ..."
              v-model="prodnameTema">
          </div>
          <div class="input-form">
            <p class="input-form__lbl">Descripción Producto Principal:</p>
            <input
              type="text"
              class="input-form__in"
              placeholder="Pollo a la plancha ..."
              v-model="proddescriptionTema">
          </div>
          <div class="precios">
            <p class="precios__title">Edita Precios o promociones (Max 4):</p>
            <table border="1">
              <thead>
                <th >Etiqueta</th>
                <th >Valor</th>
                <th >Mostrar (si/no)</th>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" class="precios__in" placeholder="pequeño" v-model="prodtagOne"></td>
                  <td><input type="text" class="precios__in" placeholder="100" v-model="prodtagvalueOne"></td>
                  <td><div class="check" style="margin-left: 5px;"><input type="checkbox" class="check__in" v-model="prodtagshowsOne"></div></td>
                </tr>
                <tr>
                  <td><input type="text" class="precios__in" placeholder="pequeño" v-model="prodtagTwo"></td>
                  <td><input type="text" class="precios__in" placeholder="100" v-model="prodtagvalueTwo"></td>
                  <td><div class="check" style="margin-left: 5px;"><input type="checkbox" class="check__in" v-model="prodtagshowsTwo"></div></td>
                </tr>
                <tr>
                  <td><input type="text" class="precios__in" placeholder="pequeño" v-model="prodtagThree"></td>
                  <td><input type="text" class="precios__in" placeholder="100" v-model="prodtagvalueThree"></td>
                  <td><div class="check" style="margin-left: 5px;"><input type="checkbox" class="check__in" v-model="prodtagshowsThree"></div></td>
                </tr>
                <tr>
                  <td><input type="text" class="precios__in" placeholder="pequeño" v-model="prodtagFour"></td>
                  <td><input type="text" class="precios__in" placeholder="100" v-model="prodtagvalueFour"></td>
                  <td><div class="check" style="margin-left: 5px;"><input type="checkbox" class="check__in" v-model="prodtagshowsFour"></div></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="input-form">
        <p class="input-form__lbl">Titulo Pie Página:</p>
        <input
          type="text"
          class="input-form__in"
          placeholder="nombre de la empresa"
          v-model="titlepieTema">
      </div>
      <div class="input-form">
        <p class="input-form__lbl">Link Pie Página:</p>
        <input
          type="text"
          class="input-form__in"
          placeholder="Ejem: nombre de la empresa"
          v-model="textlinkTema">
      </div>
      <div class="input-form">
        <p class="input-form__lbl">Texto Final Página:</p>
        <input
          type="text"
          class="input-form__in"
          placeholder="Ejem: 98562145"
          v-model="textpieTema">
      </div>
    </div>
    <div class="footer">
      <a href="#" @click="saveDataTema()" class="footer__btn">Guardar Cambios</a>
    </div>
  </div>
</template>

<script>
  // api
  import apiPlantilla from '@/api/Plantilla.js'
  import apiCarta from '@/api/Carta.js'
  // vuex
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'BaseFormCartaTema',
    computed: {
      ...mapGetters({
        dataCartaTemplate: 'dataCartaTemplate',
      }),
    },
    data() {
      return {
        titleTema: 'Titulo o Nombre de Empresa',
        subtitleTema: 'aqui pudes poner una pequeña descripcion sobre tu carta',
        showprodTema: false,
        prodnameTema: '',
        proddescriptionTema: '',
        prodtagsTema: [],
        prodvaluesTema: [],
        prodtagshowsTema: [],
        titlepieTema: 'Texto Corto',
        textlinkTema: 'Promociona un red social',
        textpieTema: 'Ejm: 986523145',
        // inputs plato especial
        prodtagOne: '',
        prodtagTwo: '',
        prodtagThree: '',
        prodtagFour: '',
        prodtagvalueOne: '',
        prodtagvalueTwo: '',
        prodtagvalueThree: '',
        prodtagvalueFour: '',
        prodtagshowsOne: '',
        prodtagshowsTwo: '',
        prodtagshowsThree: '',
        prodtagshowsFour: '',
        //
      }
    },
    watch: {
      titleTema(value) {
        if (this.dataCartaTemplate.tema) {
          this.dataCartaTemplate.tema.title = value
        }
      },
      subtitleTema(value) {
        if (this.dataCartaTemplate.tema) {
          this.dataCartaTemplate.tema.subtitle = value
        }
      },
      showprodTema(value) {
        if (this.dataCartaTemplate.tema) {
          this.dataCartaTemplate.tema.show_prod = value
        }
      },
      prodnameTema(value) {
        if (this.dataCartaTemplate.tema) {
          this.dataCartaTemplate.tema.prod_name = value
        }
      },
      proddescriptionTema(value) {
        if (this.dataCartaTemplate.tema) {
          this.dataCartaTemplate.tema.prod_description = value
        }
      },
      titlepieTema(value) {
        if (this.dataCartaTemplate.tema) {
          this.dataCartaTemplate.tema.title_pie = value
        }
      },
      textlinkTema(value) {
        if (this.dataCartaTemplate.tema) {
          this.dataCartaTemplate.tema.text_link = value
        }
      },
      textpieTema(value) {
        if (this.dataCartaTemplate.tema) {
          this.dataCartaTemplate.tema.text_pie = value
        }
      },
      //
      prodtagOne(value) {
        if (this.dataCartaTemplate.tema) {
          this.dataCartaTemplate.tema.prod_tags[0] = value
        }
      },
      prodtagTwo(value) {
        if (this.dataCartaTemplate.tema) {
          this.dataCartaTemplate.tema.prod_tags[1] = value
        }
      },
      prodtagThree(value) {
        if (this.dataCartaTemplate.tema) {
          this.dataCartaTemplate.tema.prod_tags[2] = value
        }
      },
      prodtagFour(value) {
        if (this.dataCartaTemplate.tema) {
          this.dataCartaTemplate.tema.prod_tags[3] = value
        }
      },
      prodtagvalueOne(value) {
        if (this.dataCartaTemplate.tema) {
          this.dataCartaTemplate.tema.prod_values[0] = value
        }
      },
      prodtagvalueTwo(value) {
        if (this.dataCartaTemplate.tema) {
          this.dataCartaTemplate.tema.prod_values[1] = value
        }
      },
      prodtagvalueThree(value) {
        if (this.dataCartaTemplate.tema) {
          this.dataCartaTemplate.tema.prod_values[2] = value
        }
      },
      prodtagvalueFour(value) {
        if (this.dataCartaTemplate.tema) {
          this.dataCartaTemplate.tema.prod_values[3] = value
        }
      },
      prodtagshowsOne(value) {
        if (this.dataCartaTemplate.tema) {
          this.dataCartaTemplate.tema.prod_tag_shows[0] = value
        }
      },
      prodtagshowsTwo(value) {
        if (this.dataCartaTemplate.tema) {
          this.dataCartaTemplate.tema.prod_tag_shows[1] = value
        }
      },
      prodtagshowsThree(value) {
        if (this.dataCartaTemplate.tema) {
          this.dataCartaTemplate.tema.prod_tag_shows[2] = value
        }
      },
      prodtagshowsFour(value) {
        if (this.dataCartaTemplate.tema) {
          this.dataCartaTemplate.tema.prod_tag_shows[3] = value
        }
      },
    },
    methods: {
      ...mapActions({
        changeGlobalLoading: 'changeGlobalLoading',
        setCartaTemplate: 'setCartaTemplate',
      }),
      initData: function () {
        if (this.dataCartaTemplate.tema) {
          this.titleTema = this.dataCartaTemplate.tema.title
          this.subtitleTema = this.dataCartaTemplate.tema.subtitle
          this.showprodTema = this.dataCartaTemplate.tema.show_prod
          this.prodnameTema = this.dataCartaTemplate.tema.prod_name
          this.proddescriptionTema = this.dataCartaTemplate.tema.prod_description
          this.prodtagsTema = this.dataCartaTemplate.tema.prod_tags
          this.prodvaluesTema = this.dataCartaTemplate.tema.prod_values
          this.prodtagshowsTema = this.dataCartaTemplate.tema.prod_tag_shows
          this.titlepieTema = this.dataCartaTemplate.tema.title_pie
          this.textlinkTema = this.dataCartaTemplate.tema.text_link
          this.textpieTema = this.dataCartaTemplate.tema.text_pie
          // inicalizamos los campos extra de plato principal
          this.prodtagOne = this.dataCartaTemplate.tema.prod_tags[0]
          this.prodtagTwo = this.dataCartaTemplate.tema.prod_tags[1]
          this.prodtagThree = this.dataCartaTemplate.tema.prod_tags[2]
          this.prodtagFour = this.dataCartaTemplate.tema.prod_tags[3]
          //
          this.prodtagvalueOne = this.dataCartaTemplate.tema.prod_values[0]
          this.prodtagvalueTwo = this.dataCartaTemplate.tema.prod_values[1]
          this.prodtagvalueThree = this.dataCartaTemplate.tema.prod_values[2]
          this.prodtagvalueFour = this.dataCartaTemplate.tema.prod_values[3]
          //
          this.prodtagshowsOne = this.dataCartaTemplate.tema.prod_tag_shows[0]
          this.prodtagshowsTwo = this.dataCartaTemplate.tema.prod_tag_shows[1]
          this.prodtagshowsThree = this.dataCartaTemplate.tema.prod_tag_shows[2]
          this.prodtagshowsFour = this.dataCartaTemplate.tema.prod_tag_shows[3]
        }
      },
      saveDataTema: function () {
        let data = {
          'id': this.dataCartaTemplate.tema.id,
          'title': this.dataCartaTemplate.tema.title,
          'subtitle': this.dataCartaTemplate.tema.subtitle,
          'show_prod': this.dataCartaTemplate.tema.show_prod,
          'prod_name': this.dataCartaTemplate.tema.prod_name,
          'prod_description': this.dataCartaTemplate.tema.prod_description,
          'prod_tags': this.dataCartaTemplate.tema.prod_tags,
          'prod_values': this.dataCartaTemplate.tema.prod_values,
          'prod_tag_shows': this.dataCartaTemplate.tema.prod_tag_shows,
          'title_pie': this.dataCartaTemplate.tema.title_pie,
          'text_link': this.dataCartaTemplate.tema.text_link,
          'text_pie': this.dataCartaTemplate.tema.text_pie,
        }
        this.changeGlobalLoading(true)
        apiPlantilla.saveTemaTemplateAdmin(this.dataCartaTemplate.tema.id, data).then(
          (response) => {
            this.changeGlobalLoading(false)
          }
        ).catch (
          (error) => {
            this.changeGlobalLoading(false)
            console.log(error)
          }
        )
      }
    },
    beforeMount () {
      this.initData();
    },
  }
</script>

<style lang="scss" scoped>
  .form-tema {
    padding: 5px 10px;
    &__forms{
      &__main {
        &__action {
          display: block;
          font-family: $ff-4;
          text-decoration: none;
          color: $color-4;
          border: 1px solid;
          padding: 5px 10px;
          text-align: center;
          margin-bottom: 10px;
          border-radius: 5px;
        }
        &__plato {
          border: 1px solid $color-12;
        }
      }
    }
  }
  .precios {
    padding: 10px;
    &__title {
      font-family: $ff-3;
      font-size: 14px;
      margin-bottom: 1em;
      text-transform: capitalize;
      color: $color-10;
      font-weight: bold;
    }
    &__in {
      font-family: $ff-3;
      max-width: 80px;
      height: 25px;
    }
  }
  .footer {
    margin-top: 1em;
    margin-bottom: 2em;
    padding: 10px;
    width: 80%;
    &__btn {
      display: block;
      background: #2d98da;
      color: $color-white;
      padding: 10px;
      text-align: center;
      text-decoration: none;
      font-family: $ff-4;
      border-radius: 10px;
      &:hover {
        opacity: 0.6;
        color: $color-11;
      }
    }
  }
</style>