<template lang="html">
  <div class="info-carta">
    <h3 class="info-carta__title">Registrar Información Basica de la Carta</h3>
    <form class="info-carta__form" method="POST" @submit.prevent="saveCarta()">
      <div class="input-form">
        <p class="input-form__lbl">Nombre Carta:</p>
        <input
          type="text"
          class="input-form__in"
          placeholder="mi primera carta"
          v-model="name">
      </div>
      <div class="input-form">
        <p class="input-form__lbl">WhatsApp:</p>
        <input
          type="text"
          class="input-form__in"
          placeholder="+5192653586"
          v-model="phone">
      </div>
      <div class="input-form">
        <p class="input-form__lbl">URL Web, Facebook u otro:</p>
        <input
          type="url"
          class="input-form__in"
          placeholder="https://www.facebook.com/Bingeres-100770498979461"
          v-model="fanpage">
      </div>
      <div class="input-form" v-show="false">
        <p class="input-form__lbl">Texto Extra:</p>
        <input
          type="url"
          class="input-form__in"
          placeholder="Buscanos como ..."
          v-model="faceUser">
      </div>
      <div class="info-carta__form__ctrls">
        <router-link :to="{name: 'cartas-user'}" class="info-carta__form__ctrls__btn">Volver</router-link>
        <button
          type="submit"
          class="info-carta__form__ctrls__btn green">
          Continuar
        </button>
      </div>
    </form>
    <p class="info-carta__error">{{ msjError }}</p>
  </div>
</template>

<script>
  import apiCarta from '@/api/Carta.js'
  // vuex
  import { mapActions } from 'vuex'

  export default {
    name: 'AddInfoCarta',
    data () {
      return {
        name: '',
        phone: '',
        faceUser: '',
        fanpage: '',
        msjError: '',
      }
    },
    methods: {
      ...mapActions({
        changeGlobalLoading: 'changeGlobalLoading'
      }),
      saveCarta: function () {
        this.msjError = ''
        if (this.name.length > 4) {
          let data = {
            'name': this.name,
            'face_user': this.faceUser,
            'fanpage': this.fanpage,
            'phone': this.phone,
          }
          this.changeGlobalLoading(true)
          apiCarta.addCartaUser(data).then(
            (response) => {
              this.changeGlobalLoading(false)
              this.$router.push({name: 'build-carta', params: {idCarta: response.data.id}})
            }
          ).catch(
            (error) => {
              this.msjError = 'No se pudo crear la carta, escribanos a: @bingeres'
              this.changeGlobalLoading(false)
              console.log(error)
            }
          ) 
        } else {
          this.msjError = 'Error: Ingrese un nombre Real.'
        }
      }
    }
  }
</script>

<style lang="scss" scoped >
  .info-carta {
    padding: 10px;
    @include desde($large) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    &__title {
      font-family: $ff-4;
      color: $color-4;
      text-align: center;
      font-size: 18px;
      margin-bottom: 1em;
    }
    &__error {
      color: $color-6;
      font-size: 16px;
      font-family: $ff-3;
      text-decoration: underline;
    }
    &__form {
      border: 1px solid $color-12;
      @include desde($large) {
        width: 60%;
      }
      &__ctrls {
        display: flex;
        align-items: center;
        justify-content: center;
        &__btn {
          margin: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          border: 0px;
          background: $color-4;
          color: $color-white;
          font-family: $ff-4;
          width: 100px;
          height: 30px;
          &.green {
            background: $color-8;
          }
        }
      }
    }
  }
</style>
