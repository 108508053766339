<template>
  <div class="color-tema">
    <div class="color-tema__colors">
      <p class="color-tema__colors__title">Tus Colores Guardados:</p>
      <div class="input-form">
        <p class="input-form__lbl">Color Fondo:</p>
        <input
          type="color"
          class="input-form__in"
          v-model="colorBg">
      </div>
      <div class="input-form">
        <p class="input-form__lbl">Color Principal:</p>
        <input
          type="color"
          class="input-form__in"
          v-model="colorPrimary">
      </div>
      <div class="input-form">
        <p class="input-form__lbl">Color Secundario:</p>
        <input
          type="color"
          class="input-form__in"
          v-model="colorSecondary">
      </div>
      <div class="input-form">
        <p class="input-form__lbl">Color Textos:</p>
        <input
          type="color"
          class="input-form__in"
          v-model="colorText">
      </div>
      <div class="input-form">
        <p class="input-form__lbl">Color Bordes:</p>
        <input
          type="color"
          class="input-form__in"
          v-model="colorBorder">
      </div>
    </div>
    <div class="footer">
      <a href="#" @click="saveColorsTema()" class="footer__btn">Guardar Colores</a>
    </div>
  </div>
</template>

<script>
  // api
  import apiPlantilla from '@/api/Plantilla.js'
  import apiCarta from '@/api/Carta.js'
  // vuex
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'BaseColoresCartaTema',
    computed: {
      ...mapGetters({
        dataCartaTemplate: 'dataCartaTemplate',
      }),
    },
    data() {
      return {
        colorBg: '',
        colorPrimary: '',
        colorSecondary: '',
        colorText: '',
        colorBorder: '',
      }
    },
    watch: {
      colorBg (value) {
        this.dataCartaTemplate.tema.color_bg = value
      },
      colorPrimary (value) {
        this.dataCartaTemplate.tema.color_primary = value
      },
      colorSecondary (value) {
        this.dataCartaTemplate.tema.color_secondary = value
      },
      colorText (value) {
        this.dataCartaTemplate.tema.color_text = value
      },
      colorBorder (value) {
        this.dataCartaTemplate.tema.color_border = value
      },
    },
    methods: {
      ...mapActions({
        changeGlobalLoading: 'changeGlobalLoading',
        setCartaTemplate: 'setCartaTemplate',
      }),
      initData: function () {
        if (this.dataCartaTemplate.tema) {
          this.colorBg = this.dataCartaTemplate.tema.color_bg
          this.colorPrimary = this.dataCartaTemplate.tema.color_primary
          this.colorSecondary = this.dataCartaTemplate.tema.color_secondary
          this.colorText = this.dataCartaTemplate.tema.color_text
          this.colorBorder = this.dataCartaTemplate.tema.color_border
        }
      },
      saveColorsTema: function () {
        let data = {
          'color_bg': this.dataCartaTemplate.tema.color_bg,
          'color_primary': this.dataCartaTemplate.tema.color_primary,
          'color_secondary': this.dataCartaTemplate.tema.color_secondary,
          'color_text': this.dataCartaTemplate.tema.color_text,
          'color_border': this.dataCartaTemplate.tema.color_border,
        }
        this.changeGlobalLoading(true)
        apiPlantilla.saveTemaTemplateAdmin(this.dataCartaTemplate.tema.id, data).then(
          (response) => {
            this.changeGlobalLoading(false)
          }
        ).catch (
          (error) => {
            this.changeGlobalLoading(false)
            console.log(error)
          }
        )
      }
    },
    beforeMount () {
      this.initData();
    },
  }
</script>

<style lang="scss" scoped>
  .color-tema {
    &__colors {
      &__title {
        font-family: $ff-3;
        color: $color-10;
        font-weight: bold;
      }
    }
  }
  .footer {
    margin-top: 1em;
    margin-bottom: 2em;
    padding: 10px;
    width: 80%;
    display: flex;
    justify-content: center;
    &__btn {
      display: block;
      width: 100%;
      background: #4b7bec;
      color: $color-white;
      padding: 10px;
      text-align: center;
      text-decoration: none;
      font-family: $ff-4;
      border-radius: 10px;
      &:hover {
        opacity: 0.6;
        color: $color-11;
      }
    }
  }
</style>