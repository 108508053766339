
import LoginPage from '@/views/users/LoginPage.vue'
import ProfileUserPage from '@/views/users/ProfileUserPage.vue'

const usersRutes = [
  {
    path: '/',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/user/perfil',
    name: 'user-profile',
    component: ProfileUserPage,
  },
]

export default usersRutes
