//
const baseConf = {
  // urlApi: 'http://127.0.0.1:8000',
  // baseDomain: 'http://localhost:8080',
  // server
  baseDomain: 'https://vemicarta.com',
  urlApi: 'https://server.vemicarta.com', // prod
}

export default baseConf
