<template>
  <div v-if="dataCartaTemplate">
    <component 
      :is="dataCartaTemplate.template.componente"
      v-bind="getTemaCarta"
      ></component>
  </div>
  <div v-else>
    <v-base-card-simple 
      texto="Esta URL esta disponible, Puedes acceder a Bingeres y armar tu carta (Menu)."
      action="Ir a Bingeres"
    />
  </div>
</template>

<script>
  // api
  import apiPlantilla from '@/api/Plantilla.js'
  import apiCarta from '@/api/Carta.js'
  // vuex
  import { mapActions, mapGetters } from 'vuex'
  //
  import BaseSimpleTemplate from '@/components/carta/baseTemplates/BaseSimpleTemplate.vue'
  import BaseEstandarTemplate from '@/components/carta/baseTemplates/BaseEstandarTemplate.vue'
  import BaseGaleryTemplate from '@/components/carta/baseTemplates/BaseGaleryTemplate.vue'
  import BaseSimpleCategoryImageTemplate from '@/components/carta/baseTemplates/BaseSimpleCategoryImageTemplate.vue'
  import BaseCartaCircleImageProdTemplate from '@/components/carta/baseTemplates/BaseCartaCircleImageProdTemplate.vue'
  import VBaseCardSimple from '@/components/base/VBaseCardSimple.vue'


  export default {
    name: 'CartaView',
    components: {
      BaseSimpleTemplate,
      BaseEstandarTemplate,
      VBaseCardSimple,
      BaseGaleryTemplate,
      BaseSimpleCategoryImageTemplate,
      BaseCartaCircleImageProdTemplate,
    },
    computed: {
      ...mapGetters({
        dataCartaTemplate: 'dataCartaTemplate',
      }),
      getTemaCarta: function () {
        return {'temaObj': this.dataCartaTemplate.tema}
      }
    },
    methods: {
      ...mapActions({
        changeGlobalLoading: 'changeGlobalLoading',
        setCartaTemplate: 'setCartaTemplate',
      }),
    },
  }
</script>

<style lang="scss" scoped>
  
</style>