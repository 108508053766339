<template>
  <div>
    <v-base-card-flotaing
      title="Modificar Categoria"
      closAction="Cerrar"
      postAction="Guardar"
      :showClose="true"
      :closeMethod="closeEditCategory"
      :postMethod="guardarDatos"
    >
      <div class="modificar">
        <div class="modificar__form">
          <div class="input-form">
            <p class="input-form__lbl">Nombre Categoria:</p>
            <input
              type="text"
              class="input-form__in"
              placeholder="Nombre Categoria"
              v-model="name">
          </div>
          <div class="input-form">
            <p class="input-form__lbl">Orden en que Aparece:</p>
            <input
              type="number"
              class="input-form__in"
              v-model="orden">
          </div>
          <div class="input-form">
            <p class="input-form__lbl">Imagen Categoria (No obligatorio):</p>
            <div class="image-content">
              <input
                type="file"
                class="input-form__in"
                @change="getImageCategory">
              <img v-if="categoryObject.image" :src="categoryObject.image" class="image-content__img">
            </div>
          </div>
        </div>
        <div class="modificar__footer">
          <a @click="showDelete = !showDelete" class="modificar__footer__link">Eliminar Categoria</a>
          <div class="modificar__footer__form" v-if="showDelete">
            <div class="input-form">
              <p class="input-form__lbl">Escribe: ELIMINAR</p>
              <input
                type="text"
                class="input-form__in"
                placeholder="escribe: ELIMINAR"
                v-model="deleteForm">
            </div>
            <a @click="deleteCategory()" class="modificar__footer__form__btn">Eliminar</a>
            <p class="modificar__footer__form__alert">{{ msjDelete }}</p>
          </div>
        </div>
      </div>
    </v-base-card-flotaing>
  </div>
</template>

<script>
  import apiPlato from '@/api/Plato.js'
  // vuex
  import { mapActions } from 'vuex'
  // mixin
  import { resizeImageMixin } from '@/mixins/resizeImageMixin.js'
  // components
  import VBaseCardFlotaing from '@/components/base/VBaseCardFlotaing.vue'

  export default {
    name: 'EditCategoryCarta',
    mixins: [resizeImageMixin],
    props: {
      closeEditCategory: {
        type: Function,
        default: function () {
          console.log('cerrado')
        }
      },
      finishMethodUpdate: {
        type: Function,
        default: function () {
          console.log('actualizado')
        }
      },
      finishMethodDelete: {
        type: Function,
        default: function () {
          console.log('eliminado')
        }
      },
      categoryObject: Object,
    },
    components: {
      VBaseCardFlotaing,
    },
    data() {
      return {
        name: '',
        orden: '',
        imageCategory: null,
        deleteForm: '',
        showDelete: false,
        msjDelete: '',
      }
    },
    methods: {
      ...mapActions({
        changeGlobalLoading: 'changeGlobalLoading'
      }),
      guardarDatos: function () {
        var data = new FormData()
        if (this.imageCategory) {
          data.append('image', this.imageCategory) 
        }
        if (!((this.name.length < 5 ) || (this.orden < 0 ))) {
          data.append('name', this.name)
          data.append('orden', this.orden)
          this.changeGlobalLoading(true)
          apiPlato.updateCategoryCarta(this.categoryObject.id, data).then(
            (response) => {
              this.changeGlobalLoading(false)
              this.finishMethodUpdate(response.data)
            }
          ).catch(
            (error) => {
              this.changeGlobalLoading(false)
              console.log(error)
            }
          )
        }
      },
      getImageCategory (event) {
        this.changeGlobalLoading(true)
        this.optimizePhoto(event.target.files[0]).then(
          (response) => {
            this.changeGlobalLoading(false)
            this.imageCategory = response
          }
        )
      },
      initData: function () {
        this.name = this.categoryObject.name
        this.orden = this.categoryObject.orden
      },
      deleteCategory: function () {
        this.msjDelete = ''
        if ((this.categoryObject.platos.length < 1) && (this.deleteForm === 'ELIMINAR')) {
          this.msjDelete = ''
          let data = new FormData()
          data.append('anulate', true)
          this.changeGlobalLoading(true)
          apiPlato.updateCategoryCarta(this.categoryObject.id, data).then(
            (response) => {
              this.changeGlobalLoading(false)
              this.finishMethodDelete(response.data)
              this.deleteForm = ''
            }
          ).catch(
            (error) => {
              this.changeGlobalLoading(false)
              console.log(error)
              this.deleteForm = ''
            }
          )
        } else {
          this.msjDelete = 'No se puede eliminar categoria que tiene platos'
        }
      }
    },
    beforeMount () {
      this.initData()
    },
  }
</script>

<style lang="scss" scoped>
  .modificar {
    &__footer {
      &__link {
        font-family: $ff-4;
        font-size: 14px;
        color: $color-6;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
      &__form {
        &__btn {
          border: 1px solid;
          background: $color-6;
          color: $color-3;
          padding: 5px 1em;
          &:hover {
            cursor: pointer;
            opacity: 0.6;
          }
        }
        &__alert {
          color: $color-6;
          font-family: $ff-4;
        }
      }
    }
  }
  .image-content {
    display: flex;
    align-items: center;
    &__img {
      width: 60px;
      height: 60px;
    }
  }
</style>