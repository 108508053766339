import * as types from './mutation-types'

// 
export const setCartaTemplate = ({ commit }, data) => {
  commit(types.SET_CARTA_TEMPLATE, data)
}

//
export const setPlatosCarta = ({ commit }, lista) => {
  commit(types.SET_PLATOS_CARTA, lista)
}


//
export const setTemaTemp = ({ commit }, data) => {
  commit(types.SET_TEMA_TEMP, data)
}