// services for user api
import axios from 'axios'
// import global settings
import baseConf from '@/Base'

var instance = axios.create({
  baseURL: baseConf.urlApi,
  timeout: 3000
})

const apiPlato= {
  categoriasPorCarta: function (pk) {
    //
    return instance.get('/api/plato/category/by-carta/' + pk + '/')
  },
  listCategoriesCarta: function (pk) {
    //
    return instance.get('/api/plato/category/by-carta-admin/' + pk + '/', {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    })
  },
  addCategoryCarta: function (data) {
    //
    return instance.post('/api/plato/category/add/', data, {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    })
  },
  updateCategoryCarta: function (pk, data) {
    //
    return instance.patch('/api/plato/category/update/'+ pk + '/', data, {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  createPlato: function (data) {
    //
    return instance.post('/api/plato/new-plato/add/', data, {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
      }
    })
  },
  updatePlato: function (data, pk) {
    //
    return instance.patch('/api/plato/update-plato/update/' + pk + '/', data, {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
      }
    })
  },
  createPrecio: function (data) {
    //
    return instance.post('/api/plato/price/add/', data, {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
      }
    })
  },
  deletePrecio: function (pk) {
    //
    return instance.delete('/api/plato/price/delete/' + pk + '/', {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
      }
    })
  },
  cambiarImagenPlato: function (data, pk) {
    return instance.patch('/api/plato/price/update-image/' + pk + '/', data, {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'multipart/form-data'
      }
    })
  },
}

export default apiPlato
