<template lang="html">
  <div class="login-page">
    <div class="login-page__content">
      <div class="login-page__content__left">
        <h2 class="login-page__content__left__title">Bienvenido a vemicarta</h2>
        <h3 class="login-page__content__left__subtitle">Carta Virtual Restaurante</h3>
        <p class="login-page__content__left__text">
          En esta plataforma podrás crear cartas online para restaurantes, bares, snack, juguerías y más, y todo completamente <b>GRATIS</b>. ingresa con tu cuenta de google para comenzar.
        </p>
        <div class="login-page__content__video">
          <p class="login-page__content__video__title">¿Cómo Funciona la Aplicacion web ?</p>
          <iframe 
            class="login-page__content__video__frame"
            width="100%" 
            height="100%" 
            src="https://www.youtube.com/embed/DeuGAPuHHZA" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen></iframe>
        </div>
      </div>
      <div class="login-page__content__right">
        <login />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Login from '@/components/users/Login.vue'

  export default {
    name: 'LoginPage',
    components: {
      Login
    },
    computed: {
      ...mapGetters({
        isLoggedIn: 'isLoggedIn',
        userData: 'userData',
      })
    },
    beforeMount: function () {
      if (this.isLoggedIn) {
        this.$router.push({name: 'cartas-user'})
      }
    }
  }
</script>

<style lang="scss" scoped >
  .login-page {
    background: #e7e4dc;
    @include desde($large) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__content {
      width: 100%;
      display: flex;
      align-items: center;
      &__video {
        width: 400px;
        height: 300px;
        &__title {
          font-family: $ff-4;
          color: $color-4;
          font-size: 15px;
          margin: 1em;
          text-align: center;
        }
      }
      &__left {
        display: none;
        @include desde($large) {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 60%;
        }
        &__title {
          font-family: $ff-1;
          font-size: 30px;
          text-align: center;
          color: $color-4;
          margin-bottom: 1em;
        }
        &__subtitle {
          font-family: $ff-2;
          font-size: 25px;
          text-align: center;
          font-weight: bold;
        }
        &__text {
          width: 400px;
          text-align: center;
          font-family: $ff-3;
          padding: 1em;
        }
      }
      &__right {
        width: 100%;
        @include desde ($large) {
          width: 40%;
        }
      }
    }
  }
</style>
