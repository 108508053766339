<template>
  <div class="container" :style="{
    background: getColorBackground(temaObj.color_bg), 
    borderLeftColor: getColorBorder(temaObj.color_border)}">
    <div class="header">
      <h1 class="header__title" :style="{color: getColorPrimary(temaObj.color_primary)}"> {{ temaObj.title }} </h1>
      <h2 class="header__subtitle" :style="{color: getColorPrimary(temaObj.color_primary)}"> {{ temaObj.subtitle }} </h2>
    </div>

    <!-- categoria ¨Principal -->
    <div class="category-main" v-if="temaObj.show_prod">
      <h2 class="category-main__name"> {{ temaObj.prod_name }} </h2>
      <p class="category-main__description">{{ temaObj.prod_description }}</p>
      <div class="category-main__prices" >
        <div 
          class="category-main__prices__item"
          v-for="(tag, index) in temaObj.prod_tags" 
          :key="index" 
          v-show="temaObj.prod_tag_shows[index]">
          <div 
            class="category-main__prices__name">
              <p
                class="category-main__prices__name__size">{{ tag }} </p>
              <p
                class="category-main__prices__name__price"> {{ temaObj.prod_values[index] }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- categoria 1 -->       
    <div class="category" v-for="categoria in listPlatos" :key="categoria.id">
      <p 
        class="category__name" 
        :style="{color: getColorSecondary(temaObj.color_secondary)}"> {{ categoria.name }} </p>
      <!-- producto 1 -->
      <a 
        class="category__platos" 
        v-for="producto in categoria.platos" 
        :key="producto.id" 
        @click="openDetail(producto)">
          <div class="category__platos__left">
            <p
              class="category__platos__left__name" 
              :style="{color: getColorPrimary(temaObj.color_primary)}"> {{ producto.name }} </p>
            <p 
              class="category__platos__left__description" 
              :style="{color: getColorText(temaObj.color_text)}">{{ producto.description }} </p>
          </div>
          <div class="category__platos__right">
            <p
              class="category__platos__right__price" 
              :style="{color: getColorPrimary(temaObj.color_primary)}"> 
                {{ getEtiquetaPrecioMain(producto.precios, producto.price).value }}
            </p>  
          </div>        
          <div class="category__platos__table" >
            <table class="category__platos__table__prices">
              <thead>
                <th v-for="precio in producto.precios" :key="precio.id"> 
                  <p 
                    class="category__platos__table__prices__size"
                    :style="{color: getColorPrimary(temaObj.color_primary)}"> {{ precio.tag }} </p> 
                </th>
              </thead>
              <tbody>
                <tr> 
                  <td v-for="precio in producto.precios" :key="precio.id">
                    <p 
                      class="category__platos__table__prices__price"
                      :style="{color: getColorPrimary(temaObj.color_primary)}"> {{ precio.value }}</p> 
                  </td>
                </tr>
              </tbody>
            </table>          
          </div>  
      </a>      
      
    </div>  

    <!-- footer  --> 
    <div class="footer">
      <h2 class="footer__logo" :style="{color: getColorSecondary(temaObj.color_secondary)}"> {{ temaObj.title_pie }}  </h2>
      <a 
        :href="dataCartaTemplate.fanpage"
        target="_blank"
        class="footer__link" 
        :style="{color: getColorPrimary(temaObj.color_primary)}">{{ temaObj.text_link }}</a>
      <a href="#" class="footer__phone" :style="{color: getColorPrimary(temaObj.color_primary)}">{{ temaObj.text_pie }}</a>
    </div>         
  </div>
  <BaseDetailTemplate
    v-if="showDetail"
    :productoObj="productoSelected"
    :closeFunction="closeDetail"
  >
  </BaseDetailTemplate> 

</template>
<script>  
  // vuex
  import { mapGetters } from 'vuex'
  // mixins
  import { getColorsTemplateMixin } from '@/mixins/getColorsTemplateMixin.js'
  import { cartaMixin } from '@/mixins/cartaMixin.js'

  import BaseDetailTemplate from './BaseDetailTemplate.vue'

  export default {
    mixins: [cartaMixin, getColorsTemplateMixin,],
    props: {
      temaObj: {
        type: Object,
        default: null 
      },
    },
    components: {
      BaseDetailTemplate,
    },
    data() {
      return {
        productoSelected: null,
        showDetail: false
      }
    }, 
    computed: {
      ...mapGetters({
        listPlatos: 'listPlatos',
        dataCartaTemplate: 'dataCartaTemplate',
      }),
    },
    methods: {
    },
  }
</script>

<style lang="scss" scoped>

  .container {
    background-color: $color-3;
    border-left:  solid $color-6 3px;
    min-height: 100vh;
  }
  .header{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 1em;
    padding-top: 1em;
    &__title{
        color: $color-4;
        font-family: $ff-2;
        font-weight: bold;
        @include desde($large) {
          font-size: 35px;
        };
    }
    &__subtitle{
        color: $color-4;
        font-family: $ff-3;
        overflow: hidden;
        font-size: 18px;
        height: 24px;
        margin: 0 1px;
    }
}

.category-main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  //align-items: center; 
  text-align: center;
  overflow: hidden;
  &__name{
    font-family: $ff-7;
    font-size: 20px;
    font-weight: bold;
    @include desde($large) {
      font-size: 24px;
    };
  }
  &__description{
    font-family: $ff-3;
    margin-bottom: 0;
    font-size: 15px;
    @include desde($large) {
      font-size: 18px;
    };
  }
  &__prices{
      display: flex;
      justify-content: center;
      width: 100%;
      &__item {
        display: flex;
        justify-content: center;
        margin-top: 1em;
        width: 24%;
        @include desde($large) {
          width: 15%;
        };
      }
      &__name{
        &__size{
          font-family: $ff-3;
          text-transform: capitalize;
          font-size: 22px;
        }
        &__price{
          font-family: $ff-4;
          font-weight: bold;
          font-size: 20px;
          @include desde($large) {
            font-size: 22px;
          };
        }
      }
  }
}
.category{
  display: flex;
  flex-direction: column;
  padding: 1em;
  &__name{
      color: $color-1;
      font-family: $ff-4;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 0.5em;
      text-transform: capitalize;   
  }
  &__platos{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5em;
    width: 100%;
    &:hover { 
      cursor: pointer;
      opacity: 0.5;
    }
    &__left {
      display: flex;
      flex-direction: column;
      font-family: $ff-7;
      width: 82%; 
      @include desde ($large) {
        width: 60%;
      }       
      &__name{
        color: $color-4;
        text-transform: capitalize;    
        font-weight: bold;
        font-size: 15px;            
      }
      &__description{
        color: $color-5;
        font-family: $ff-3;
        max-height: 35px;
        font-size: 14px;
        overflow: hidden;
        text-align: justify;
      }            
    }
    &__right {
      width: 18%;
      @include desde ($large) {
        display: none;
      } 
      &__price{
        color: $color-4;
        font-family: $ff-4;
        font-weight: bold;
        font-size: 18px;
        text-align: right;
        @include desde ($large) {
          display: none;
        } 
      }
    }
    &__table {
      width: 40%;
      display: none;
      overflow-x: auto;
      @include desde ($large) {
        display: flex;
        justify-content: flex-start;
        flex-direction: row-reverse;
      }         
      &__prices {
        &__size {              
          color: $color-4;
          font-family: $ff-7;
          font-size: 12px;
          display: block;
          text-decoration: underline;
          width: 100px;
          white-space: nowrap; 
          overflow: hidden; 
          text-overflow: ellipsis; 
          text-transform: capitalize;
        }
        &__price {
          color: $color-4;
          font-size: 18px;
          text-align: center;
          font-family: $ff-4;
          padding: 5px; 
          font-weight: bold;
        }
      }
        
    }
  
  }
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $color-black;
  align-items: center;
  padding: 10px;
  &__logo {
    font-family: $ff-6;
  }
  &__link {
    color: $color-4;
    text-decoration: none;
    &:hover {
      opacity: 0.7;
    }
  }
  &__phone {
    color: $color-4;
    text-decoration: none;
    font-family: $ff-5;
    &:hover {
      opacity: 0.7;
    }
  }
}
</style>