// importamos los tipos de mutacion

import * as types from './mutation-types'

const mutations = {
  // PARA CARGAR DATOS E HOME
  [types.SET_CARTA_TEMPLATE] (state, data) {
    // seteamos
    state.dataCartaTemplate = data
  },
  [types.SET_PLATOS_CARTA] (state, lista) {
    // seteamos
    state.listPlatos = lista
  },
  [types.SET_TEMA_TEMP] (state, data) {
    // seteamos
    state.temaTempData = data
  },
}

export default mutations
