<template lang="html">
  <div class="">
    <build-carta />
  </div>
</template>

<script>
  import BuildCarta from '@/components/cartaAdmin/BuildCarta.vue'

  export default {
    name: 'BuildCartaPage',
    components: {
      BuildCarta
    }
  }
</script>

<style lang="css" scoped >

</style>
