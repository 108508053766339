<template lang="html">
  <div class="">
    <mis-cartas />
  </div>
</template>

<script>
  import MisCartas from '@/components/cartaAdmin/MisCartas.vue'

  export default {
    name: 'CartasUserPage',
    components: {
      MisCartas
    }
  }
</script>

<style lang="scss" scoped >

</style>
