<template>
  <div class="msj-flotain">
    <span class="msj-flotain__text" :style="{background: bg}">{{msj}}</span>
  </div>
</template>

<script>
//
export default {
  name: 'MensajeFlotain',
  props: {
    bg: String,
    msj: String
  },
}
</script>

<style lang="scss">
.msj-flotain {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 98vh;
  width: 100%;
  background-color: rgba(0,0,0,0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
  &__text {
    font-family: $ff-2;
    padding-left: 1em;
    padding-right: 1em;
    color: white;
    border-radius: 5px;
  }
}
</style>