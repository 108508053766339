
import CartaPage from '@/views/carta/CartaPage.vue'
// ========== CARTA ADMIN ===========
import IndexPage from '@/views/IndexPage.vue'
import CartasUserPage from '@/views/cartaAdmin/CartasUserPage.vue'
import AddInfoEmpresaCarta from '@/views/cartaAdmin/AddInfoEmpresaCarta.vue'
import BuildCartaPage from '@/views/cartaAdmin/BuildCartaPage.vue'
import EditTemplatePage from '@/views/cartaAdmin/EditTemplatePage.vue'

// Espacio de prueba de templates
import BaseSnacksTemplate from '@/components/carta/baseTemplates/BaseSnacksTemplate.vue'
import BaseCartaCircleImageProdTemplate from '@/components/carta/baseTemplates/BaseCartaCircleImageProdTemplate.vue'

const cartaRutes = [
  {
    path: '/:usernameEmpresa',
    name: 'ver-carta',
    component: CartaPage,
  },

  {
    path: '/prueba/templates',
    name: 'prueba-template',
    component: BaseSnacksTemplate,
  },
  {
    path: '/test/templates',
    name: 'test-template',
    component: BaseCartaCircleImageProdTemplate,
  },


  // ==================================
  // ========== CARTA ADMIN ===========
  // ==================================
  {
    path: '/panel/user',
    name: 'panel-carta',
    component: IndexPage,
    children: [
      {
        path: 'mis-cartas',
        name: 'cartas-user',
        component: CartasUserPage,
        query: null,
      },
      {
        path: 'nueva-carta',
        name: 'add-carta',
        component: AddInfoEmpresaCarta,
      },
      {
        path: 'build-carta/:idCarta',
        name: 'build-carta',
        component: BuildCartaPage,
      },
      {
        path: 'theme-carta/:idCarta/edit',
        name: 'theme-carta',
        component: EditTemplatePage,
      },
    ]
  },
]

export default cartaRutes
