import { mapActions, mapGetters } from 'vuex'
import notImg from '@/assets/not-image.png'

export const cartaMixin = {
  computed: {
    ...mapGetters({
    })
  },
  methods: {
    ...mapActions({
    }),
    openDetail: function(producto) {
      this.productoSelected = producto
      this.showDetail= true
    },
    closeDetail: function() {
      this.productoSelected = null
      this.showDetail=false
    },
    getImageProduct: function (producto) {
      if (producto.image_one) {
        return producto.image_one
      } else {
        return notImg
      }
    },
    getImageCategory: function (categoria) {
      if (categoria.image) {
        return categoria.image
      } else {
        return notImg
      }
    },
    getImageCarta: function (carta) {
      if (carta.image) {
        return carta.image
      } else {
        return notImg
      }
    },
    getEtiquetaPrecioMain: function (precios, defaultValue=0) {
      var precio = {}
      if (precios.length > 0) {
        precio = precios[precios.length - 1]
      } else {
        precio = {
          'tag': '',
          'value': defaultValue
        }
      }
      for (let i = 0; i < precios.length; i++) {
        const element = precios[i]
        if (element.main == true) {
          precio = element
        }
      }
      return precio
    }
  }
}